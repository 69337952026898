import React, { FC } from 'react';

import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  text: {
    fontWeight: 500,
    fontSize: '11px',
    color: '#828282',
    display: 'flex',
    flexDirection: 'row',
    '&::after, &::before': {
      content: `''`,
      flex: '1 1',
      borderBottom: '1px dashed #828282',
      margin: 'auto',
    },
    '&::after': {
      marginLeft: '15px',
    },
    '&::before': {
      marginRight: '15px',
    },
  },
});

interface Params {
  text: string;
}

const HorizontalRuleHint: FC<Params> = React.memo(({ text }) => {
  const styles = useStyles();

  return <Typography className={styles.text}>{text}</Typography>;
});

export default HorizontalRuleHint;
