import { Box, Button, LinearProgress } from "@material-ui/core";
import React, { FC } from "react";

interface IFormProps {
  onSubmit: (params?: any[]) => any;
  loading?: boolean;
  disabled?: boolean;
}

const Form: FC<IFormProps> = ({ onSubmit, children, disabled, loading }) => {
  const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <form onSubmit={handleSubmit}>
      {children}

      <Box width="80%" margin="0 auto" minHeight={5}>
        {loading && <LinearProgress />}
      </Box>

      <Box mt={2}>
        <Button
          fullWidth={true}
          color="primary"
          variant="contained"
          type="submit"
          disabled={disabled || loading}
        >
          UPDATE
        </Button>
      </Box>
    </form>
  );
};

export default Form;
