import React, { FC } from 'react';

import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import Logo from 'components/UI/Logo/Logo';

const NotFound: FC = () => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    flexDirection="column"
    width="100%"
    height="100vh"
  >
    <Box padding={1}>
      <Logo />
    </Box>

    <Typography
      color="error"
      variant="h3"
      component="h1"
      gutterBottom={true}
      data-testid="display-error"
    >
      404 - Not Found
    </Typography>

    <Typography color="error" data-testid="display-error-message">
      We couldn't find this page.
    </Typography>
  </Box>
);

export default NotFound;
