import { Middleware, RequestContext } from '@optionsai/oai-api-js';
import getEnvVar from 'utils/getEnvVar';

const redirectURI = getEnvVar('REACT_APP_ID');

class CallInterceptor implements Middleware {
  public pre(context: RequestContext): Promise<any> {
    return new Promise((res) => {
      return res(undefined);
    });
  }

  public post(context: RequestContext): Promise<any> {
    return new Promise((res) => {
      if ((context as any).response.status === 401) {
        window.location.href = `${redirectURI}?redirect=${window.location.href}`;
        return;
      }

      return res(undefined);
    });
  }
}

export default CallInterceptor;
