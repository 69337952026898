import React, { FC } from 'react';

import Typography from '@material-ui/core/Typography';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  viewAll: {
    textDecoration: 'none',
    textTransform: 'none',
    display: 'block',
    width: 'fit-content !important',
    minWidth: 'fit-content',
    color: '#2C74F6',
    position: 'relative',
    paddingTop: '2px',
    '&>:nth-child(2)': {
      transition: '0.2s ease-in-out',
      position: 'absolute',
      right: 8,
      top: '4px',
      height: '14px',
      width: '14px',
      [theme.breakpoints.up('sm')]: {
        height: '17px',
        width: '27px',
      },
    },
    '&>:nth-child(1)': {
      transition: '0.2s ease-in-out',
      fontSize: '11px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '13px',
      },
      paddingRight: '33px',
      opacity: 1,
    },
    '&:hover': {
      cursor: 'pointer',
      '&>:nth-child(1)': {
        opacity: 0.85,
      },
      '&>:nth-child(2)': {
        right: 0,
      },
    },
  },
}));

interface Params {
  href: string;
  label: string;
  replace?: boolean;
  forceRelocate?: boolean;
  onClick?: () => void;
}

const TextArrowLink: FC<Params> = React.memo(
  ({ href, label, replace = false, forceRelocate, onClick }) => {
    const styles = useStyles();

    const handleRelocate = () => {
      window.location.replace(href);
    };

    return (
      <>
        {onClick || forceRelocate ? (
          <div onClick={onClick || handleRelocate} className={styles.viewAll}>
            <Typography>{label}</Typography>

            <ArrowForwardIcon fontSize="small" />
          </div>
        ) : (
          <a
            className={styles.viewAll}
            href={href}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Typography>{label}</Typography>

            <ArrowForwardIcon fontSize="small" />
          </a>
        )}
      </>
    );
  }
);

export default TextArrowLink;
