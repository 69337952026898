import React, { useMemo } from "react";

interface IProps {
  value?: number;
}

const DollarValue = ({ value }: IProps) => {
  const memoized = useMemo(() => {
    if (value === undefined) {
      return null;
    }

    return toDollarValue(value);
  }, [value]);
  return <span data-testid="DollarValue">{memoized}</span>;
};

/**
 *
 * @param num number value
 * @returns dollar representation of number in type string
 */
export const toDollarValue = (num: number) => {
  return `$${num.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })}`;
};

export default DollarValue;
