import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import TransferItem from 'components/Banking/Transfer';
import { Tab, Tabs } from '@material-ui/core';
import useTransferHistory from 'hooks/useTransferHistory';

export enum TransferStatusEnum {
  // pending
  PLACED = 'PLACED',
  PENDING = 'PENDING',
  SENT = 'SENT',
  PENDING_PRINTING = 'PENDING_PRINTING',
  CANCEL_REQUESTED = 'CANCEL_REQUESTED',
  CANCEL_PENDING = 'CANCEL_PENDING',
  APPROVED = 'APPROVED',
  FUNDS_POSTED = 'FUNDS_POSTED',
  SENT_TO_BANK = 'SENT_TO_BANK',

  // held
  BEING_HELD = 'BEING_HELD',
  FUNDS_HELD = 'FUNDS_HELD',

  // completed
  REJECTED = 'REJECTED',
  CANCELED = 'CANCELED',
  COMPLETE = 'COMPLETE',
  RETURNED = 'RETURNED',

  // unknown
  VOID = 'VOID',
  STOP_PAYMENT = 'STOP_PAYMENT',
  UNKNOWN = 'UNKNOWN',
}

export type TabName = 'Pending' | 'Held' | 'Complete';

const TransferHistory: FC = observer(() => {
  const { error, loading, transfersByStatus } = useTransferHistory();

  const [transferType, setTransferType] = useState<TabName>('Pending');

  const handleChange = (_: React.ChangeEvent<{}>, newValue: TabName) => {
    setTransferType(newValue);
  };

  return (
    <Box padding={2}>
      <Typography variant="h3" gutterBottom={true}>
        Transfer History
      </Typography>

      <Tabs
        value={transferType}
        onChange={handleChange}
        aria-label="simple tabs example"
      >
        <Tab label="Pending" value={'Pending'} />
        <Tab label="Held" value={'Held'} />
        <Tab label="Complete" value={'Complete'} />
      </Tabs>

      {loading && 'Loading...'}

      {error && <Typography>{error.message}</Typography>}

      {transferType === 'Pending' ? (
        <Box>
          <Typography
            style={{
              marginBottom: 12,
              fontWeight: 600,
              fontSize: 17,
              marginTop: 24,
            }}
          >
            Pending Transfers
          </Typography>
          <Typography style={{ marginBottom: 16 }}>
            • ACH Deposits placed after 3pm ET on a business day, will be
            process the following business day.
          </Typography>

          <Typography style={{ marginBottom: 12 }}>
            • Deposits can take 1-5 business days to complete depending on your
            bank and account history.
          </Typography>

          <Typography style={{ marginBottom: 12 }}>
            • Balances are updated daily at 6am ET. You will be notified once a
            transfer has been completed.
          </Typography>
        </Box>
      ) : transferType === 'Held' ? (
        <Box maxWidth={600}>
          <Typography
            style={{ marginBottom: 12, fontWeight: 600, fontSize: 17 }}
          >
            Held Transfers
          </Typography>

          <Typography style={{ marginBottom: 12 }}>
            • Held transfers require further review. Please check your email for
            more information or contact{' '}
            <Typography
              component={'a'}
              href="mailto:support@optionsai.com"
              style={{
                color: '#007bff',
                display: 'inline',
                cursor: 'pointer',
              }}
            >
              support@optionsai.com
            </Typography>{' '}
            for more information.
          </Typography>
        </Box>
      ) : null}

      <Box paddingY={2}>
        {transfersByStatus[transferType]?.map((transfer) => (
          <TransferItem key={transfer.id} transfer={transfer} />
        ))}
      </Box>

      {!transfersByStatus[transferType].length && !loading && (
        <Typography>Nothing here.</Typography>
      )}
    </Box>
  );
});

export default TransferHistory;
