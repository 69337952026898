import React, { FC } from 'react';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

const Responsive: FC = ({ children }) => (
  <Container>
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      {children}
    </Box>
  </Container>
);

Responsive.displayName = 'Responsive';

export default Responsive;
