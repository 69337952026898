import { useEffect, useState } from 'react';

type IFuncWithParams = (param1?: any, param2?: any, param3?: any) => any;

const useApiCall = (
  apiCall: IFuncWithParams,
  onComplete: IFuncWithParams,
  clearErrorVariables?: string[]
): {
  loading: boolean;
  errMsg: string;
  makeCall: () => void;
  response: any;
} => {
  // state
  const [errMsg, setErrMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const [call, setCall] = useState(false);
  const [response, setResponse] = useState<any>();

  // make api call
  useEffect(() => {
    if (call === true) {
      (async function run() {
        setResponse(undefined);

        setCall(false);

        setLoading(true);

        try {
          const res = await apiCall();

          setLoading(false);

          onComplete();

          setResponse(res.data || res);
        } catch (error: any) {
          setLoading(false);

          setErrMsg(error.message);
        }
      })();
    }
    /* eslint-disable-next-line  */
  }, [call]);

  // clear error message whenever a for variable changes
  useEffect(() => {
    setErrMsg('');
    /* eslint-disable-next-line  */
  }, clearErrorVariables);

  const makeCall = () => {
    setCall(true);
  };

  return { loading, errMsg, makeCall, response };
};

export default useApiCall;
