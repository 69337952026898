import React, { FC } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from 'components/UI/Dialog/Dialog';
import { Box, Button, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  dialog: {
    zIndex: 6,
    padding: '50px',
    maxWidth: '450px',
    boxShadow: '0 4px 33px 8px rgb(0 0 0 / 10%) !important',
    borderRadius: '1px',
    display: 'block',
    [theme.breakpoints.down('xs')]: {
      margin: 0,
      height: '100%',
      maxWidth: '100%',
      maxHeight: '100%',
      padding: 30,
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  title: {
    marginBottom: '15px',
    fontSize: '16px',
    fontWeight: 800,
  },
  description: {
    fontSize: '15px',
  },
  button: {
    borderRadius: '6px',
    color: theme.palette.primary.contrastText,
    fontSize: '14px',
    fontWeight: 600,
    textTransform: 'none',
    whiteSpace: 'nowrap',
    opacity: 1,
    transition: 'all 0.3s',
    '&:hover': {
      backgroundColor: '#2C74F6',
      opacity: 0.8,
    },
  },
}));

interface Params {
  open?: boolean;
  onClose?: () => void;
}

const SuccessfulBankConnectionDialog: FC<Params> = React.memo(
  ({ open = false, onClose }) => {
    const styles = useStyles();

    return (
      <Dialog
        noBackdrop
        open={open}
        onClose={onClose}
        paperClassName={styles.dialog}
      >
        <Box mb={4}>
          <Typography className={styles.title}>
            Thanks for connecting your account
          </Typography>

          <Typography className={styles.description}>
            You will be able to start making deposits to your Options AI account
            after <b>2 minutes.</b>
          </Typography>
        </Box>

        <Button
          variant="contained"
          color="primary"
          className={styles.button}
          fullWidth
          disableElevation
          onClick={onClose}
        >
          Continue
        </Button>
      </Dialog>
    );
  }
);

export default SuccessfulBankConnectionDialog;
