import React, { FC, useMemo, useState } from 'react';

import withWidth, { isWidthDown, WithWidth } from '@material-ui/core/withWidth';
import { makeStyles, Box, Typography, Button } from '@material-ui/core';
import PageHeader from 'components/Nav/PageHeader/PageHeader';
import DesktopNavbar from 'components/Nav/desktop/Navbar';

interface IResponsiveLayoutProps extends WithWidth {
  pageTitle?: string;
  returnPageTitle?: string;
  returnPageHandler?: () => void;
  searchDisabled?: boolean;
}

const useStyles = (isMobile?: boolean) => {
  const display = isMobile ? 'block' : 'flex';
  const leftWidth = isMobile ? '100%' : '40%';
  const rightWidth = isMobile ? '100%' : '60%';

  return makeStyles({
    root: {
      display,
      minHeight: '100vh',
    },
    left: {
      width: leftWidth,
      maxHeight: '100vh',
      overflow: 'auto',
      position: 'relative',
      borderRight: isMobile ? 'none' : '1px solid #F0F0F0',
    },
    right: {
      width: rightWidth,
      position: isMobile ? 'initial' : 'relative',
    },
  });
};

enum LayoutEnum {
  DEFAULT,
  SEARCH,
}

const ResponsiveLayout: FC<IResponsiveLayoutProps> = ({
  width,
  children,
  pageTitle,
  returnPageTitle,
  returnPageHandler,
  searchDisabled = false,
}) => {
  const isMobile = useMemo(() => {
    return isWidthDown('md', width);
  }, [width]);

  const classes = useStyles(isMobile)();

  const [layout, setLayout] = useState<LayoutEnum>(LayoutEnum.DEFAULT);

  const toggleLayout = () => {
    if (layout === LayoutEnum.DEFAULT) {
      setLayout(LayoutEnum.SEARCH);
      return;
    }

    setLayout(LayoutEnum.DEFAULT);
  };

  const isSearching = useMemo(() => {
    return layout === LayoutEnum.SEARCH;
  }, [layout]);

  return (
    <Box className={classes.root}>
      <Box className={classes.left}>
        {isMobile && (
          <PageHeader header={pageTitle} goBack={returnPageHandler} />
        )}

        {!isMobile && !isSearching ? (
          <Box height="100%">
            <DesktopNavbar
              toggleSearch={toggleLayout}
              isSearching={isSearching}
              searchDisabled={searchDisabled}
            />

            <Box
              width="100%"
              height="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Button variant="text" onClick={returnPageHandler}>
                <Typography color="textSecondary" variant="body1">
                  {'< '}
                  {returnPageTitle}
                </Typography>
              </Button>

              <Typography variant="h1" style={{ color: '#000' }}>
                {pageTitle}
              </Typography>

              <Box pb={20} />
            </Box>
          </Box>
        ) : (
          <></>
        )}
      </Box>

      <Box className={classes.right}>{children}</Box>
    </Box>
  );
};

export default withWidth()(ResponsiveLayout);
