/**
 * Get environment variable, or default if missing
 * @param value Environment variable name
 * @param defaultValue Default value if missing
 */
const getEnvVar = (value: string, defaultValue?: string) => {
  const envVar = process.env[String(value)];

  if (typeof envVar === 'undefined' || envVar.length === 0) {
    if (typeof defaultValue === 'undefined') {
      throw new Error(`Var '${value}' could not be found`);
    }

    return defaultValue;
  }

  return envVar;
};

export default getEnvVar;
