import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { observer } from 'mobx-react-lite';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

import Responsive from 'components/UI/Responsive';
import { useOktaAuth } from '@okta/okta-react';
import { REACT_APP_ID } from 'core/constants';
import { QueryClient, QueryClientProvider } from 'react-query';
import axios, { AxiosInstance } from 'axios';
import config from '../core/api/config';

const queryClient = new QueryClient();

const APIContext = createContext<{ client: AxiosInstance } | undefined>(
  undefined
);

export const useApiContext = () => {
  const context = useContext(APIContext);

  if (context === undefined) {
    throw new Error('You must be wrapped in ApiProvider');
  }

  return context;
};

const Bootstrap: FC = observer(({ children }) => {
  const { oktaAuth } = useOktaAuth();
  const [initializing, setInitializing] = useState<boolean>(true);

  const accesstoken = oktaAuth.getAccessToken();

  useEffect(() => {
    if (!initializing) {
      return;
    }

    const token = oktaAuth.getAccessToken();

    if (!token) {
      window.location.href = REACT_APP_ID;
      return;
    }

    setInitializing(false);
  }, [initializing, oktaAuth]);

  const axiosClient = useMemo(() => {
    if (!accesstoken) {
      return undefined;
    }

    return axios.create({
      baseURL: config.basePath,
      headers: {
        Authorization: `Bearer ${accesstoken}`,
      },
    });
  }, [accesstoken]);

  if (initializing || !axiosClient) {
    return (
      <Responsive>
        <Box padding={1}>
          <CircularProgress
            variant="indeterminate"
            size={50}
            thickness={8}
            data-testid="circular-progress"
          />
        </Box>
      </Responsive>
    );
  }

  return (
    <APIContext.Provider value={{ client: axiosClient }}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </APIContext.Provider>
  );
});

export default Bootstrap;
