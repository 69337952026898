import { useOktaAuth } from '@okta/okta-react';
import React, { FC, useCallback, useEffect, useState } from 'react';

import { Box, IconButton, MenuItem, Typography } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import { Account, AccountApi, Configuration } from '@optionsai/oai-api-js';
import config from 'core/api/config';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';

import getEnvVar from 'utils/getEnvVar';
import ResponsiveLayout from 'pages/ResponsiveLayout';
import CallInterceptor from 'core/api/Interceptor';

interface IBankingProps extends RouteComponentProps {}

const tradeAppURI = getEnvVar('REACT_APP_TRADE');

export let BANKS: number[] = [1];

const WireTransferPage: FC<IBankingProps> = ({ history }) => {
  const [accountData, setAccountData] = useState<Account>();
  const { oktaAuth } = useOktaAuth();

  const handleBack = () => {
    window.location.href = `${tradeAppURI}/account`;
  };

  const onWidthdrawalFundsSelection = () => {
    history.push('/banking/withdrawal', { from: true });
  };

  const getAccountData = useCallback(async () => {
    const accessToken = await oktaAuth.getAccessToken();

    const callInterceptor = new CallInterceptor();

    const api = new AccountApi(
      new Configuration({
        basePath: config.basePath,
        accessToken,
        middleware: [callInterceptor],
      })
    );

    try {
      const response = await api.getAccount();

      setAccountData(response);
    } catch (err) {
      console.log(err);
    }
  }, [oktaAuth]);

  useEffect(() => {
    getAccountData();
  }, [getAccountData]);

  if (BANKS.length < 1) {
    return <Redirect to="/banking/link" />;
  }

  return (
    <ResponsiveLayout
      pageTitle="Wire Transfers"
      returnPageHandler={handleBack}
      returnPageTitle="Account"
    >
      <Box p={2.5} pt={4}>
        <Box>
          <Typography variant="body1" gutterBottom={true}>
            Account Funding
          </Typography>
          <Typography variant="body1">
            To fund your account, please send funds using the following details:
          </Typography>
        </Box>

        <Box pt={3} justifyContent="space-between" width="100%" display="flex">
          <Box>
            <Typography variant="body1">Bank Name:</Typography>
          </Box>

          <Box>
            <Typography variant="body1">BMO/Harris Bank</Typography>
          </Box>
        </Box>

        <Box pt={2} justifyContent="space-between" width="100%" display="flex">
          <Box>
            <Typography variant="body1">Bank Address:</Typography>
          </Box>

          <Box>
            <Box>
              <Typography align="right" variant="body1">
                111 West Monroe St
              </Typography>
            </Box>
            <Box>
              <Typography align="right" variant="body1">
                9th Floor
              </Typography>
            </Box>
            <Box>
              <Typography align="right" variant="body1">
                Chicago, IL 60603
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box pt={2} justifyContent="space-between" width="100%" display="flex">
          <Box>
            <Typography variant="body1">ABA #: or</Typography>
          </Box>

          <Box>
            <Typography variant="body1">071000288</Typography>
          </Box>
        </Box>

        <Box pt={2} justifyContent="space-between" width="100%" display="flex">
          <Box>
            <Typography variant="body1">SWIFT Number:</Typography>
          </Box>

          <Box>
            <Typography variant="body1">HATRUS44</Typography>
          </Box>
        </Box>

        <Box pt={2} justifyContent="space-between" width="100%" display="flex">
          <Box>
            <Typography variant="body1">Beneficiary:</Typography>
          </Box>

          <Box>
            <Typography variant="body1">A/C# 3713286</Typography>
          </Box>
        </Box>

        <Box pt={2} justifyContent="space-between" width="100%" display="flex">
          <Box>
            <Typography variant="body1">Account:</Typography>
          </Box>

          <Box>
            <Typography variant="body1">Apex Clearing Corporation</Typography>
          </Box>
        </Box>

        <Box pt={2} justifyContent="space-between" width="100%" display="flex">
          <Box>
            <Typography variant="body1">FFC A/C#:</Typography>
          </Box>

          <Box>
            <Typography variant="body1">
              {accountData ? accountData.accountNumber : ''}
            </Typography>
          </Box>
        </Box>

        <Box pt={6}>
          <MenuItem onClick={onWidthdrawalFundsSelection} button={true}>
            <Box
              justifyContent="space-between"
              width="100%"
              display="flex"
              alignItems="center"
            >
              <Box>
                <Typography variant="h6">Withdraw Funds</Typography>
              </Box>

              <IconButton>
                <ChevronRight />
              </IconButton>
            </Box>
          </MenuItem>
        </Box>
      </Box>
    </ResponsiveLayout>
  );
};

export default withRouter(WireTransferPage);
