import React from 'react';

import ReactSkeleton, { SkeletonProps } from 'react-loading-skeleton';

import { makeStyles, Theme } from '@material-ui/core/styles';

interface IStyleProps {
  maxWidth?: string | number;
}

const useStyles = makeStyles<Theme, IStyleProps>({
  skeleton: {
    maxWidth: (props) => (props.maxWidth ? props.maxWidth : 'none'),
  },
});

interface Params extends SkeletonProps {
  maxWidth?: string | number;
}

const Skeleton = React.memo((props: Params) => {
  const styles = useStyles({ maxWidth: props.maxWidth });

  return <ReactSkeleton {...props} className={styles.skeleton} />;
});

export default Skeleton;
