import React, { FC } from 'react';

import { RouteComponentProps, withRouter } from 'react-router';

import { Box, Button, Typography } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import ResponsiveLayout from 'pages/ResponsiveLayout';

const WithdrawalSuccess: FC<RouteComponentProps> = ({ history, location }) => {
  const handleBack = () => {
    history.replace('/banking');
  };

  return (
    <ResponsiveLayout
      pageTitle="Withdraw Funds"
      returnPageHandler={handleBack}
      returnPageTitle="Banking"
    >
      <Box pt={6}>
        <Box justifyContent="center" display="flex" width="100%">
          <Box
            bgcolor="#78E08F"
            p={2}
            width={100}
            height={100}
            borderRadius="50%"
            justifyContent="center"
            display="flex"
            alignItems="center"
            color="#fff"
          >
            <Check style={{ fontSize: 72 }} />
          </Box>
        </Box>

        <Box width="80%" margin="0 auto">
          <Box pt={4}>
            <Typography variant="h6" align="center">
              Instructions to withdraw have been received.
            </Typography>
          </Box>

          <Box pt={4}>
            <Typography variant="h6" align="center">
              You should expect to see them in your account in the next 24
              hours.
            </Typography>
          </Box>
        </Box>

        <Box pt={6} width="100%" display="flex" justifyContent="center">
          <Box width="40%">
            <Button
              onClick={handleBack}
              fullWidth={true}
              size="large"
              variant="contained"
              color="primary"
            >
              Close
            </Button>
          </Box>
        </Box>
      </Box>
    </ResponsiveLayout>
  );
};

export default withRouter(WithdrawalSuccess);
