import { useCallback, useState } from 'react';

const useToggle = (defaultState = false) => {
  const [open, setOpen] = useState(defaultState);

  const toggle = useCallback(() => {
    setOpen(!open);
  }, [open, setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  return { toggle, open, handleClose, handleOpen };
};

export default useToggle;
