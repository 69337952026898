import React, { FC, useState, useEffect } from 'react';

import { observer } from 'mobx-react-lite';
import { Account, AccountApi, Configuration } from '@optionsai/oai-api-js';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import config from 'core/api/config';
import optionLevelTrades from 'core/domain/optionLevelTrades';
import { useOktaAuth } from '@okta/okta-react';

interface ExtendedAccount extends Account {
  accountType: 'CASH' | 'MARGIN';
}

const AccountDescription: FC = observer(() => {
  const { oktaAuth } = useOktaAuth();

  const [accountApi, setAccountApi] = useState<AccountApi>();
  const [account, setAccount] = useState<ExtendedAccount>();

  const [initializing, setInitializing] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();

  useEffect(() => {
    if (!initializing) {
      return;
    }

    setInitializing(true);
    const accessToken = oktaAuth.getAccessToken();

    try {
      if (!accessToken) {
        throw new Error('Needs access token');
      }

      const configuration = new Configuration({
        basePath: config.basePath,
        accessToken,
      });

      setAccountApi(new AccountApi(configuration));
    } catch (err) {
      setError(err as any);
    } finally {
      setInitializing(false);
    }
  }, [oktaAuth, initializing]);

  useEffect(() => {
    if (!accountApi) {
      return;
    }

    setLoading(true);

    accountApi
      .getAccount()
      .then((account) => setAccount(account as any))
      .catch((err) => setError(err))
      .finally(() => setLoading(false));
  }, [accountApi]);

  const fullName = [
    account?.firstName,
    account?.middleName,
    account?.lastName,
  ].join(' ');

  const tradesEnabled = account?.optionLevel
    ? optionLevelTrades(account?.optionLevel)
    : [];

  const showInstructions =
    account && account.optionLevel && account.optionLevel < 3;

  return (
    <Box padding={2}>
      <Typography style={{ fontWeight: 600 }} variant="h2" gutterBottom={true}>
        Account Info
      </Typography>
      {error && <Typography color="error">{error.message}</Typography>}
      {loading && <Typography color="textSecondary">Loading...</Typography>}
      <Grid container={true}>
        <Grid item={true} xs={6}>
          <Typography>Name</Typography>
        </Grid>
        <Grid item={true} xs={6}>
          <Typography>{fullName}</Typography>
        </Grid>
      </Grid>
      <Grid container={true}>
        <Grid item={true} xs={6}>
          <Typography>Account Number</Typography>
        </Grid>
        <Grid item={true} xs={6}>
          <Typography>{account?.accountNumber}</Typography>
        </Grid>
      </Grid>
      <Grid container={true}>
        <Grid item={true} xs={6}>
          <Typography>Account Type</Typography>
        </Grid>
        <Grid item={true} xs={6}>
          <Typography>{account?.accountType === 'CASH' ? 'Cash' : 'Margin'}</Typography>
        </Grid>
      </Grid>
      <Grid container={true}>
        <Grid item={true} xs={6}>
          <Typography>Options Level</Typography>
        </Grid>
        <Grid item={true} xs={6}>
          <Typography>{account?.optionLevel}</Typography>
        </Grid>
      </Grid>
      <Grid container={true}>
        <Grid item={true} xs={6}>
          <Typography>Trades Enabled</Typography>
        </Grid>
        <Grid item={true} xs={6}>
          {tradesEnabled.map((t) => (
            <Typography key={t}>{t}</Typography>
          ))}
        </Grid>
      </Grid>

      {showInstructions && (
        <Box marginTop={4}>
          <Typography>
            You need more options trading experience to enable spreads.
          </Typography>
          <Typography>
            To request access to spreads, contact{' '}
            <Link href="mailto:support@optionsai.com">
              support@optionsai.com
            </Link>
          </Typography>
        </Box>
      )}
    </Box>
  );
});

export default AccountDescription;
