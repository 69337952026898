import React, { FC } from 'react';

interface Params {
  size?: number;
  color?: string;
}

const ErrorIcon: FC<Params> = React.memo(({ size = 50, color = '#2C74F6' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0415 29.9584C12.4234 30.3403 12.9096 30.5313 13.4998 30.5313C14.0901 30.5313 14.5762 30.3403 14.9582 29.9584L20.9998 23.9167L27.0936 30.0104C27.4755 30.3924 27.9533 30.5743 28.5269 30.5563C29.0991 30.5396 29.5762 30.3403 29.9582 29.9584C30.3401 29.5764 30.5311 29.0903 30.5311 28.5C30.5311 27.9097 30.3401 27.4236 29.9582 27.0417L23.9165 21L30.0103 14.9063C30.3922 14.5243 30.5741 14.0465 30.5561 13.4729C30.5394 12.9007 30.3401 12.4236 29.9582 12.0417C29.5762 11.6597 29.0901 11.4688 28.4998 11.4688C27.9096 11.4688 27.4234 11.6597 27.0415 12.0417L20.9998 18.0834L14.9061 11.9896C14.5241 11.6077 14.0471 11.425 13.4748 11.4417C12.9012 11.4597 12.4234 11.6597 12.0415 12.0417C11.6596 12.4236 11.4686 12.9097 11.4686 13.5C11.4686 14.0903 11.6596 14.5764 12.0415 14.9584L18.0832 21L11.9894 27.0938C11.6075 27.4757 11.4255 27.9528 11.4436 28.525C11.4603 29.0986 11.6596 29.5764 12.0415 29.9584ZM20.9998 41.8334C18.1179 41.8334 15.4096 41.2861 12.8748 40.1917C10.3401 39.0986 8.13525 37.6146 6.26025 35.7396C4.38525 33.8646 2.90123 31.6597 1.80817 29.125C0.713726 26.5903 0.166504 23.882 0.166504 21C0.166504 18.1181 0.713726 15.4097 1.80817 12.875C2.90123 10.3403 4.38525 8.13544 6.26025 6.26044C8.13525 4.38544 10.3401 2.90071 12.8748 1.80627C15.4096 0.713215 18.1179 0.166687 20.9998 0.166687C23.8818 0.166687 26.5901 0.713215 29.1248 1.80627C31.6596 2.90071 33.8644 4.38544 35.7394 6.26044C37.6144 8.13544 39.0985 10.3403 40.1915 12.875C41.286 15.4097 41.8332 18.1181 41.8332 21C41.8332 23.882 41.286 26.5903 40.1915 29.125C39.0985 31.6597 37.6144 33.8646 35.7394 35.7396C33.8644 37.6146 31.6596 39.0986 29.1248 40.1917C26.5901 41.2861 23.8818 41.8334 20.9998 41.8334Z"
        fill={color}
      />
    </svg>
  );
});

export default ErrorIcon;
