import React, { FC } from 'react';

import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem, { ListItemProps } from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

interface IStatementsProps {
  loading?: boolean;
  statements?: any[];
}

function ListItemLink(props: ListItemProps<'a', { button?: true }>) {
  return <ListItem button component="a" {...props} />;
}

const Statements: FC<IStatementsProps> = ({ loading, statements = [] }) => {
  return (
    <Box py={2}>
      <Box marginLeft={2} mb={2}>
        {loading && (
          <Typography gutterBottom={true} variant="overline">
            Loading your documents
          </Typography>
        )}

        {Boolean(!loading && statements.length) && (
          <Typography gutterBottom={true} variant="overline">
            Date
          </Typography>
        )}

        {Boolean(!loading && !statements.length) && (
          <Typography gutterBottom={true} variant="body1">
            Your account does not have any statements.
          </Typography>
        )}
      </Box>

      <List>
        {statements.map((item: any) => (
          <ListItemLink key={item.docID} href={item.url}>
            <ListItemText primary={item.documentDate} />
          </ListItemLink>
        ))}
      </List>
    </Box>
  );
};

export default Statements;
