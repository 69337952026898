import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router';

import { Box, List, ListItem, Typography } from '@material-ui/core';

import ResponsiveLayout from 'pages/ResponsiveLayout';
import useReturn from 'hooks/useReturn';
import { getEnvVar } from '@optionsai/oai-common-js';

interface IProps extends RouteComponentProps {}

const Settings: FC<IProps> = ({ history }) => {
  const { handleReturn } = useReturn();

  const handleForgotPassword = async () => {
    window.location.href = `${getEnvVar(
      'REACT_APP_ID'
    )}/logout?nextPath=forgot-password`;
  };

  return (
    <ResponsiveLayout
      pageTitle="Profile"
      returnPageTitle="Account"
      returnPageHandler={handleReturn}
    >
      <List disablePadding={true}>
        <ListItem
          button={true}
          divider={true}
          onClick={() => history.push('/settings/account', { from: true })}
        >
          <Box p={1}>
            <Typography variant="h6">Account</Typography>
          </Box>
        </ListItem>

        <ListItem
          onClick={() =>
            history.push('/settings/investment-profile', { from: true })
          }
          button={true}
          divider={true}
        >
          <Box p={1}>
            <Typography variant="h6">Investment Profile</Typography>
          </Box>
        </ListItem>

        <ListItem
          onClick={() => history.push('/settings/services', { from: true })}
          button={true}
          divider={true}
        >
          <Box p={1}>
            <Typography variant="h6">Services</Typography>
          </Box>
        </ListItem>

        <ListItem onClick={handleForgotPassword} button={true} divider={true}>
          <Box p={1}>
            <Typography variant="h6">Forgot Password</Typography>
          </Box>
        </ListItem>
      </List>
    </ResponsiveLayout>
  );
};

export default Settings;
