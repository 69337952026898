import React, { FunctionComponent } from 'react';

interface ILogoProps {
  size?: number;
}

const Logo: FunctionComponent<ILogoProps> = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 205 205"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_1011_125824"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="205"
      height="205"
    >
      <rect
        x="0.182617"
        y="0.806641"
        width="203.941"
        height="203.941"
        fill="#C4C4C4"
      />
    </mask>
    <g mask="url(#mask0_1011_125824)">
      <path
        d="M146.201 28.2074C135.102 21.9643 121.922 19.1896 108.048 17.8022V56.6481C117.76 58.0355 126.084 61.5039 133.021 68.4406C141.345 76.7648 145.507 87.8636 145.507 101.737C145.507 115.611 141.345 126.709 133.021 135.034C124.697 144.051 114.291 148.213 101.805 148.213C89.3191 148.213 78.914 144.051 70.5898 135.727C64.3468 128.79 60.1847 119.773 58.7973 109.368H49.0859H17.1768C17.8704 123.241 21.3388 135.727 27.5819 146.132C35.2123 158.619 44.9238 169.024 58.1037 175.96C71.2835 182.897 85.8507 186.366 101.805 186.366C118.454 186.366 133.021 182.897 145.507 175.96C158.687 169.024 168.398 159.312 176.029 146.132C183.659 132.953 187.128 118.385 187.128 101.737C187.128 85.0889 183.659 70.5217 176.029 57.3418C169.092 45.5493 158.687 35.1442 146.201 28.2074Z"
        fill="#090940"
      />
    </g>
  </svg>
);

export default Logo;
