import React from 'react';

import { Router, Switch, Route } from 'react-router-dom';

import { createBrowserHistory } from 'history';
import { Security } from '@okta/okta-react';

import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';

import Settings from './Settings/mobile/Settings';

import AccountSummary from './AccountSummary/Mobile/AccountSummary';

import BankingPage from './Banking/BankingPage';
import DepositPage from './Banking/DepositPage';
import WithdrawPage from './Banking/WithdrawPage';
import SuccessPage from './Banking/SuccessPage';
import WireTransferPage from './Banking/WireTransferPage';

import WithdrawalFailed from './Banking/Wire/Failed';
import WithdrawalSuccess from './Banking/Wire/Success';

import Documents from './Documents/mobile/Documents';
import UpdateAccount from './Settings/Account/mobile/UpdateAccount';
import InvestmentProfile from './Settings/InvestmentProfile/mobile/InvestmentProfile';
import Services from './Settings/Services/mobile/Services';

import Bootstrap from './Bootstrap';
import NotFound from './NotFound';
import SecureRoute from './SecureRoute';

import theme from '@optionsai/oai-mui-theme';
import AnalyticRoute from 'containers/Analytics/AnalyticsRoute';
import storage from 'core/auth/tokenStorage';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { getEnvVar } from '@optionsai/oai-common-js';

export const history = createBrowserHistory();

const oktaAuth = new OktaAuth({
  issuer: `https://auth.options.ai/oauth2/${getEnvVar(
    'REACT_APP_OKTA_ISSUER'
  )}`,
  clientId: getEnvVar('REACT_APP_OKTA_CID'),
  redirectUri: window.location.origin + '/auth/callback',
  pkce: true,
  tokenManager: {
    autoRenew: true,
    secure: true,
    storage: storage,
  },
});

export default () => {
  const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router history={history}>
        <Security restoreOriginalUri={restoreOriginalUri} oktaAuth={oktaAuth}>
          <Bootstrap>
            <Switch>
              <AnalyticRoute
                title="Settings"
                exact={true}
                path="/"
                component={Settings}
              />

              <SecureRoute
                exact={true}
                path="/account"
                component={AccountSummary}
              />

              <SecureRoute exact={true} path="/settings" component={Settings} />

              <SecureRoute
                exact={true}
                path="/settings/account"
                component={UpdateAccount}
              />

              <SecureRoute
                exact={true}
                path="/settings/investment-profile"
                component={InvestmentProfile}
              />

              <SecureRoute path="/settings/services" component={Services} />

              <SecureRoute path="/documents" component={Documents} />

              <SecureRoute
                exact={true}
                path="/banking-wires"
                component={WireTransferPage}
              />

              <AnalyticRoute
                title="Banking"
                exact={true}
                path="/banking"
                component={BankingPage}
              />

              <AnalyticRoute
                title="Deposit Funds"
                exact={true}
                path="/banking/deposit"
                component={DepositPage}
              />

              <SecureRoute
                exact={true}
                path="/banking/withdraw"
                component={WithdrawPage}
              />

              <SecureRoute
                exact={true}
                path="/banking/withdrawal"
                component={WithdrawPage}
              />

              <SecureRoute
                exact={true}
                path="/banking/withdrawal/success"
                component={WithdrawalSuccess}
              />

              <SecureRoute
                exact={true}
                path="/banking/withdrawal/failed"
                component={WithdrawalFailed}
              />

              <AnalyticRoute
                title="Deposit Initiated"
                exact={true}
                path="/transfers/success/deposit"
                component={SuccessPage}
              />

              <AnalyticRoute
                title="Withdrawal Initiated"
                exact={true}
                path="/transfers/success/withdrawal"
                component={SuccessPage}
              />

              <AnalyticRoute
                title="Transfer Initiated"
                exact={true}
                path="/transfers/success"
                component={SuccessPage}
              />

              <Route component={NotFound} />
            </Switch>
          </Bootstrap>
        </Security>
      </Router>
    </ThemeProvider>
  );
};
