import React, { FC } from 'react';

import LinkedAccounts from 'containers/Banking/LinkedAccounts';
import InitiateTransfer from 'containers/Banking/InitiateTransfer';
import TransferHistory from 'containers/Banking/TransferHistory';

import ResponsiveLayout from 'pages/ResponsiveLayout';

import useReturn from 'hooks/useReturn';

const BankingPage: FC = () => {
  const { handleReturn } = useReturn();

  return (
    <>
      <ResponsiveLayout
        pageTitle="Banking"
        returnPageTitle="Account"
        returnPageHandler={handleReturn}
      >
        <LinkedAccounts />
        <InitiateTransfer />
        <TransferHistory />
      </ResponsiveLayout>
    </>
  );
};

export default BankingPage;
