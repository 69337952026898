import React, { FC } from 'react';

interface Params {
  size?: number;
  color?: string;
}

const CompleteIcon: FC<Params> = React.memo(
  ({ size = 50, color = '#2C74F6' }) => {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 42 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.0832 30.5833L32.7707 15.8958L29.854 12.9792L18.0832 24.75L12.1457 18.8125L9.229 21.7292L18.0832 30.5833ZM20.9998 41.8333C18.1179 41.8333 15.4096 41.2861 12.8748 40.1917C10.3401 39.0986 8.13525 37.6146 6.26025 35.7396C4.38525 33.8646 2.90123 31.6597 1.80817 29.125C0.713726 26.5903 0.166504 23.8819 0.166504 21C0.166504 18.1181 0.713726 15.4097 1.80817 12.875C2.90123 10.3403 4.38525 8.13542 6.26025 6.26042C8.13525 4.38542 10.3401 2.90069 12.8748 1.80625C15.4096 0.713194 18.1179 0.166666 20.9998 0.166666C23.8818 0.166666 26.5901 0.713194 29.1248 1.80625C31.6596 2.90069 33.8644 4.38542 35.7394 6.26042C37.6144 8.13542 39.0985 10.3403 40.1915 12.875C41.286 15.4097 41.8332 18.1181 41.8332 21C41.8332 23.8819 41.286 26.5903 40.1915 29.125C39.0985 31.6597 37.6144 33.8646 35.7394 35.7396C33.8644 37.6146 31.6596 39.0986 29.1248 40.1917C26.5901 41.2861 23.8818 41.8333 20.9998 41.8333Z"
          fill={color}
        />
      </svg>
    );
  }
);

export default CompleteIcon;
