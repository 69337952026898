import { Box, Grid, IconButton, ListItem, Typography } from "@material-ui/core";
import { ArrowRight } from "@material-ui/icons";
import React, { FC } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import ResponsiveLayout from "pages/ResponsiveLayout";

const InvestmentProfile: FC<RouteComponentProps> = ({ history, location }) => {
  const handleBack = () => {
    if (location.state && location.state.from) {
      history.goBack();
      return;
    }

    history.replace("/settings");
  };

  // const onEmploymentSelection = () => {
  //   history.push("/settings/investment-profile/employment", { from: true });
  // };

  // const onTaxStatusSelection = () => {
  //   history.push("/settings/investment-profile/tax-status", { from: true });
  // };

  // const onFinancialSelection = () => {
  //   history.push("/settings/investment-profile/financial", { from: true });
  // };

  // const onExperienceSelection = () => {
  //   history.push("/settings/investment-profile/experience", { from: true });
  // };

  // const onGoalsSelection = () => {
  //   history.push("/settings/investment-profile/goals", { from: true });
  // };

  // const onPoliticalExposureSelection = () => {
  //   history.push("/settings/investment-profile/political-exposure", {
  //     from: true
  //   });
  // };

  // const onControlPersonSelection = () => {
  //   history.push("/settings/investment-profile/control-person", {
  //     from: true
  //   });
  // };

  // const onAffiliationSelection = () => {
  //   history.push("/settings/investment-profile/affiliation", {
  //     from: true
  //   });
  // };

  // const onTrustedPersonSelection = () => {
  //   history.push("/settings/investment-profile/trusted-person", {
  //     from: true
  //   });
  // };

  return (
    <ResponsiveLayout
      pageTitle="Investment Profile"
      returnPageTitle="Profile"
      returnPageHandler={handleBack}
    >
      <ListItem divider={true} button={true}>
        <Box width="100%">
          <Grid container={true} justify="space-between" alignItems="center">
            <Typography variant="h6">Employment</Typography>

            <IconButton disabled={true}>
              <ArrowRight />
            </IconButton>
          </Grid>
        </Box>
      </ListItem>

      <ListItem divider={true} button={true}>
        <Box width="100%">
          <Grid container={true} justify="space-between" alignItems="center">
            <div>
              <Typography variant="h6">Tax Status</Typography>

              <Box px={1}>
                <Typography variant="body2" color="textSecondary">
                  Filling Status
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Dependents
                </Typography>
              </Box>
            </div>

            <IconButton disabled={true}>
              <ArrowRight />
            </IconButton>
          </Grid>
        </Box>
      </ListItem>

      <ListItem divider={true} button={true}>
        <Box width="100%">
          <Grid container={true} justify="space-between" alignItems="center">
            <div>
              <Typography variant="h6">Financial</Typography>

              <Box px={1}>
                <Typography variant="body2" color="textSecondary">
                  Annual Income
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Total Net Worth
                </Typography>

                <Typography variant="body2" color="textSecondary">
                  Liquid Worth
                </Typography>

                <Typography variant="body2" color="textSecondary">
                  Federal Tax Bracket
                </Typography>
              </Box>
            </div>

            <IconButton disabled={true}>
              <ArrowRight />
            </IconButton>
          </Grid>
        </Box>
      </ListItem>

      <ListItem divider={true} button={true}>
        <Box width="100%">
          <Grid container={true} justify="space-between" alignItems="center">
            <div>
              <Typography variant="h6">Profile</Typography>

              <Box px={1}>
                <Typography variant="body2" color="textSecondary">
                  Experience
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Objectives
                </Typography>
              </Box>
            </div>

            <IconButton disabled={true}>
              <ArrowRight />
            </IconButton>
          </Grid>
        </Box>
      </ListItem>

      <ListItem divider={true} button={true}>
        <Box width="100%">
          <Grid container={true} justify="space-between" alignItems="center">
            <div>
              <Typography variant="h6">Investment Goals</Typography>

              <Box px={1}>
                <Typography variant="body2" color="textSecondary">
                  Goals
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Timeline
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Risk Tolerance
                </Typography>
              </Box>
            </div>

            <IconButton disabled={true}>
              <ArrowRight />
            </IconButton>
          </Grid>
        </Box>
      </ListItem>

      <ListItem divider={true} button={true}>
        <Box width="100%">
          <Grid container={true} justify="space-between" alignItems="center">
            <Typography variant="h6">Political Exposure</Typography>

            <IconButton disabled={true}>
              <ArrowRight />
            </IconButton>
          </Grid>
        </Box>
      </ListItem>

      <ListItem divider={true} button={true}>
        <Box width="100%">
          <Grid container={true} justify="space-between" alignItems="center">
            <Typography variant="h6">Control Person</Typography>

            <IconButton disabled={true}>
              <ArrowRight />
            </IconButton>
          </Grid>
        </Box>
      </ListItem>

      <ListItem divider={true} button={true}>
        <Box width="100%">
          <Grid container={true} justify="space-between" alignItems="center">
            <Typography variant="h6">Affiliation</Typography>

            <IconButton disabled={true}>
              <ArrowRight />
            </IconButton>
          </Grid>
        </Box>
      </ListItem>

      <ListItem divider={true} button={true}>
        <Box width="100%">
          <Grid container={true} justify="space-between" alignItems="center">
            <Typography variant="h6">Trusted Person</Typography>

            <IconButton disabled={true}>
              <ArrowRight />
            </IconButton>
          </Grid>
        </Box>
      </ListItem>

      <Box left={0} width="100%" p={2}>
        <Typography variant="body2" align="center">
          To edit your account details (email, address etc) please contact
          support@optionsai.com. We will respond with instructions. Thank you.
        </Typography>
      </Box>
    </ResponsiveLayout>
  );
};

export default withRouter(InvestmentProfile);
