import React, { FC } from 'react';

import AppBar, { AppBarProps } from '@material-ui/core/AppBar';
import makeStyles from '@material-ui/styles/makeStyles';
import theme from '@optionsai/oai-mui-theme';

const useStyles = makeStyles({
  root: {
    backgroundColor: '#fff',
    boxShadow: '0 1px 0 0 #F0F0F0',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
});

interface IProps extends AppBarProps {}

const NavSurface: FC<IProps> = ({ children, position }) => {
  const classes = useStyles();

  return (
    <AppBar position={position} className={classes.root}>
      {children}
    </AppBar>
  );
};

export default NavSurface;
