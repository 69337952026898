import React, { FC } from 'react';
import { useEffect } from 'react';
import { DocumentApi, Configuration, Confirm } from '@optionsai/oai-api-js';
import config from '../core/api/config';
import CallInterceptor from '../core/api/Interceptor';
import { useMemo } from 'react';
import { useState } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import { ListItemLink } from './Taxes';
import ListItemText from '@material-ui/core/ListItemText';
import { DateTime } from 'luxon';

import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useOktaAuth } from '@okta/okta-react';

interface ITradeConfirmsProps {}

const TradeConfirms: FC<ITradeConfirmsProps> = (props) => {
  const { oktaAuth } = useOktaAuth();
  const token = oktaAuth.getAccessToken();

  const [confirms, setConfirms] = useState<Confirm[] | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);

  const [endDate, setEndDate] = useState<DateTime>(DateTime.local());

  const apiClient = useMemo(() => {
    if (!token) {
      return undefined;
    }

    const intercepter = new CallInterceptor();

    const api = new DocumentApi(
      new Configuration({
        accessToken: token,
        basePath: config.basePath,
        middleware: [intercepter],
      })
    );

    return api;
  }, [token]);

  useEffect(() => {
    if (!apiClient) {
      return;
    }

    (async () => {
      setLoading(true);

      const res = await apiClient.getConfirms({
        startDate: endDate.startOf('month').startOf('day').toISODate(),
        endDate: endDate.endOf('month').endOf('day').toISODate(),
      });

      setConfirms(res);

      setLoading(false);
    })();
  }, [apiClient, endDate]);

  return (
    <Box py={2}>
      <Box
        justifyContent="space-between"
        display="flex"
        alignItems="center"
        px={2}
      >
        <MuiPickersUtilsProvider utils={LuxonUtils}>
          <DatePicker
            variant="dialog"
            openTo="year"
            views={['year', 'month']}
            value={endDate}
            disableFuture={true}
            onChange={(d) => setEndDate(DateTime.fromISO(d?.toISODate() || ''))}
          />
        </MuiPickersUtilsProvider>

        {loading && <CircularProgress size={20} />}
      </Box>

      <List>
        {confirms?.map((item) => {
          if (!item.processDate) {
            return;
          }

          const dateOnly = item.processDate.split(' ')[0];

          const dateToDisplay = DateTime.fromString(
            dateOnly,
            'MM/dd/yyyy'
          ).toFormat('MM/dd/yyyy');

          return (
            <ListItemLink key={item.confirmUrl} href={item.confirmUrl}>
              <ListItemText primary={dateToDisplay} />
            </ListItemLink>
          );
        })}
      </List>

      {Boolean(!confirms && !loading) && (
        <Box px={2}>
          <Typography>
            No confirms for {endDate.toFormat('MMM yyyy')}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default TradeConfirms;
