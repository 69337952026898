import React, { FC, useEffect, useState, useMemo } from 'react';

import { useOktaAuth } from '@okta/okta-react';
import { Configuration, DocumentApi, Document } from '@optionsai/oai-api-js';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import format from 'date-fns/format';

import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import Statements from 'containers/Statements';
import Tax from 'containers/Taxes';

import config from 'core/api/config';
import ResponsiveLayout from 'pages/ResponsiveLayout';
import CallInterceptor from 'core/api/Interceptor';
import TradeConfirms from '../../../containers/TradeConfirms';
import useReturn from 'hooks/useReturn';

interface IDocumentsProps extends RouteComponentProps {}

enum ActiveTab {
  STATEMENTS,
  TAXES,
  CONFIRMS,
}

const Documents: FC<IDocumentsProps> = React.memo(() => {
  const { oktaAuth } = useOktaAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [response, setResponse] = useState<Document[]>();

  const [activeTab, setActiveTab] = useState<ActiveTab>(ActiveTab.STATEMENTS);

  const { handleReturn } = useReturn();

  const handleStatementsSelection = () => {
    setActiveTab(ActiveTab.STATEMENTS);
  };

  const handleTaxSelection = () => {
    setActiveTab(ActiveTab.TAXES);
  };

  const handleConfirmsSelection = () => {
    setActiveTab(ActiveTab.CONFIRMS);
  };

  const getHistory = async () => {
    setLoading(true);

    const accessToken = oktaAuth.getAccessToken();

    const intercepter = new CallInterceptor();

    const api = new DocumentApi(
      new Configuration({
        accessToken,
        basePath: config.basePath,
        middleware: [intercepter],
      })
    );

    const now = new Date();
    const endDate = format(now, 'yyyy-MM-dd');
    const startDate = '2019-01-01';
    // const startDate = format(subYears(now, 1), 'yyyy-MM-dd');

    const res = await api.getDocuments({
      endDate,
      startDate,
    });

    if (res) {
      setResponse(res);
    }

    setLoading(false);
  };

  const statements = useMemo(() => {
    if (!response) {
      return;
    }

    return response.filter((item: any) => item.docType === 2);
  }, [response]);

  const taxDocs = useMemo(() => {
    if (!response) {
      return;
    }

    return response.filter((item: any) => item.docType === 100);
  }, [response]);

  useEffect(() => {
    if ((!statements || !taxDocs) && !response) {
      getHistory();
    }

    // eslint-disable-next-line
  }, [statements, taxDocs]);

  return (
    <ResponsiveLayout
      pageTitle="Documents"
      returnPageTitle="Account"
      returnPageHandler={handleReturn}
    >
      <Tabs
        variant="scrollable"
        value={activeTab}
        aria-label="simple tabs example"
      >
        <Tab
          onClick={handleStatementsSelection}
          label="Account Statements"
          value={ActiveTab.STATEMENTS}
        />

        <Tab
          onClick={handleTaxSelection}
          label="Tax Documents"
          value={ActiveTab.TAXES}
        />

        <Tab
          onClick={handleConfirmsSelection}
          label="Trade Confirms"
          value={ActiveTab.CONFIRMS}
        />
      </Tabs>

      <Box p={1.5} pt={2}>
        {activeTab === ActiveTab.STATEMENTS && (
          <Statements loading={loading} statements={statements} />
        )}

        {activeTab === ActiveTab.TAXES && (
          <Tax loading={loading} taxDocs={taxDocs} />
        )}

        {activeTab === ActiveTab.CONFIRMS && <TradeConfirms />}
      </Box>
    </ResponsiveLayout>
  );
});

export default withRouter(Documents);
