// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { action, observable } from 'mobx';

import FormField from './helpers/FormField';

interface IServices {
  reinvestment: boolean;
  householding: boolean;
  directCommunication: boolean;
}

class Services {
  @observable
  public dividenReinvestment = new FormField<boolean>(false);

  @observable
  public householdingServices = new FormField<boolean>(false);

  @observable
  public directCommunication = new FormField<boolean>(false);

  @action
  public set = (payload: IServices) => {
    this.dividenReinvestment.set(payload.reinvestment);
    this.householdingServices.set(payload.householding);
    this.directCommunication.set(payload.directCommunication);
  };
}

export default Services;
