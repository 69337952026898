import Cookies, { CookieAttributes } from 'js-cookie';

import { getEnvVar, isLocalHost } from '@optionsai/oai-common-js';
import { SimpleStorage } from '@okta/okta-auth-js';

const uat = getEnvVar('REACT_APP_AUTH_UAT', 'false') === 'true';

const domain = isLocalHost()
  ? 'localhost'
  : uat
    ? 'uat.options.ai'
    : 'options.ai';

const cookieOptions = {
  domain,
  path: '/',
  secure: !isLocalHost(),
} as CookieAttributes;

const storage = {
  getItem(key) {
    return Cookies.get(key);
  },
  setItem(key, value) {
    Cookies.set(key, value, cookieOptions);
  },
} as SimpleStorage;

export default storage;
