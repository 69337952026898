import React from 'react';
import { render } from 'react-dom';

import * as Sentry from '@sentry/browser';

import Root from 'pages/Root';

import 'typeface-inter';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.ENVIRONMENT,
  release: process.env.GITHUB_SHA,
});

const rootComponent = <Root />;
const rootNode = document.getElementById('root');

render(rootComponent, rootNode);
