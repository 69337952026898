import React, { FC, useState, useContext, useEffect, useMemo } from 'react';

import { observer } from 'mobx-react-lite';
import { Redirect, useLocation } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {
  BankingApi,
  Configuration,
  TransferApi,
  WithdrawAmount,
} from '@optionsai/oai-api-js';
import { useOktaAuth } from '@okta/okta-react';
import { makeStyles } from '@material-ui/core/styles';

import BankingContext from 'contexts/BankingContext';
import config from 'core/api/config';
import Money from 'components/UI/Money';
import HorizontalRuleHint from 'components/UI/HorizontalRuleHint';
import TextArrowLink from 'components/UI/Nav/TextArrowLink';
import { REACT_APP_TRADE } from 'core/constants';
import useToggle from 'hooks/useToggle';
import { CircularProgress, useMediaQuery } from '@material-ui/core';
import TextWithSkeleton from 'components/UI/Loading/TextWithSkeleton';
import TransferIcon from 'components/UI/icons/TransferIcon';
import ErrorIcon from 'components/UI/icons/ErrorIcon';
import CallInterceptor from 'core/api/Interceptor';
import { IOnWithdrawalFormSubmitParams } from 'pages/Banking/Wire/Withdrawal';
import WithdrawForm from 'components/Withdrawal/WithdrawForm';
import WithdrawalForm from 'components/Withdrawal/WithdrawalForm';
import { InfoOutlined } from '@material-ui/icons';
import { useQuery } from 'react-query';
import { useApiContext } from 'pages/Bootstrap';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    fontSize: '24px',
    fontWeight: 900,
    marginBottom: theme.spacing(2),
  },
  pageSubtitle: {
    fontSize: '12px',
    fontWeight: 700,
    color: '#828282',
  },
  availableWithdraw: {
    color: '#2C74F6',
    fontSize: '24px',
    fontWeight: 700,
  },
  balanceBox: {
    backgroundColor: '#E8F4FD',
    borderRadius: '5px',
    padding: '10px 12px',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      padding: '20px',
    },
  },
  primaryColor: {
    color: '#2C74F6',
  },
  secondaryColor: {
    color: '#828282',
  },
  fw500: {
    fontWeight: 500,
  },
  fw600: {
    fontWeight: 600,
  },
  fw700: {
    fontWeight: 700,
  },
  fs10: {
    fontSize: '10px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '13px',
    },
  },
  fs11: {
    fontSize: '11px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '12px',
    },
  },
  fs12: {
    fontSize: '12px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '15px',
    },
  },
  fs12Important: {
    fontSize: '12px !important',
    [theme.breakpoints.up('sm')]: {
      fontSize: '15px !important',
    },
  },
  mb12: {
    marginBottom: '12px',
  },
  mb10: {
    marginBottom: '10px',
  },
  link: {
    textTransform: 'none',
    textDecoration: 'none',
    fontSize: '11px',
    fontWeight: '500 !important' as any,
    color: '#2C74F6',
    opacity: 1,
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.75,
    },
    transition: 'opacity 0.15s ease-in-out',
    width: 'fit-content',
    [theme.breakpoints.up('sm')]: {
      fontSize: '12px',
    },
  },
  textButton: {
    textTransform: 'none',
    fontSize: '11px',
  },
  noPaddingLeft: {
    paddingLeft: 0,
    justifyContent: 'flex-start',
  },
  form: {
    width: '-webkit-fill-available',
    maxWidth: '-webkit-fill-available',
    '@supports (width: -moz-available)': {
      maxWidth: '-mox-available',
      width: '-moz-available',
    },
    paddingLeft: '50px',
    [theme.breakpoints.down(775)]: {
      paddingLeft: 0,
    },
  },
  accountInformation: {
    maxWidth: 300,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
  },
  transferTitle: {
    fontSize: '16px',
    fontWeight: 700,
    color: '#2C74F6',
    margin: '21px 0px',
  },
  icon: {
    marginRight: '6px',
    height: '14px',
    width: '14px',
  },
}));

enum WithheldFundsType {
  NO_WITHOLDS = 'NO_WITHOLDS',
  PENDING_DEPOSITS = 'PENDING_DEPOSITS',
  WORKING_ORDERS = 'WORKING_ORDERS',
  PENDING_DEPOSITS_AND_WORKING_ORDERS = 'PENDING_DEPOSITS_AND_WORKING_ORDERS',
}

export interface WithdrawalInfo extends WithdrawAmount {
  recentDeposits?: RecentDeposit[];
}

const Withdraw: FC = observer(() => {
  const styles = useStyles();
  const { oktaAuth } = useOktaAuth();
  const banking = useContext(BankingContext);
  const api = useApiContext();

  const { data, isLoading: balanceLoading } = useQuery(
    ['/transfers/funds'],
    () => api.client.get<WithdrawalInfo>('/transfers/funds'),
    {
      cacheTime: 0,
      refetchInterval: 15000,
    }
  );

  const withdrawalInfo = data?.data;

  const { open: breakdownExpanded, toggle: toggleBreakdownExpanded } =
    useToggle(true);

  const [bankingApi, setBankingApi] = useState<BankingApi>();
  const [transferApi, setTransferApi] = useState<TransferApi>();

  const [amount, setAmount] = useState<number>();
  const [submitted, setSubmitted] = useState(false);

  const [accountsLoading, setAccountsLoading] = useState(false);
  const [transerLoading, setTransferLoading] = useState(false);

  const [error, setError] = useState<string>();

  const [transferError, setTransferError] = useState<boolean>(false);

  useEffect(() => {
    const accessToken = oktaAuth.getAccessToken();

    try {
      if (!accessToken) {
        throw new Error('Needs access token');
      }

      const configuration = new Configuration({
        basePath: config.basePath,
        accessToken,
      });

      setBankingApi(new BankingApi(configuration));
      setTransferApi(new TransferApi(configuration));
    } catch (e) {
      setError((e as any).message);
    }
  }, [oktaAuth]);

  useEffect(() => {
    if (!bankingApi) {
      return;
    }

    setAccountsLoading(true);

    bankingApi
      .getLinkedAccounts()
      .then((accounts) => banking.setLinkedAccounts(accounts))
      .catch((err) => setError(err.message))
      .finally(() => setAccountsLoading(false));
  }, [banking, bankingApi]);

  const withheldFundsType = useMemo(() => {
    if (!withdrawalInfo) {
      return undefined;
    }

    let condition1: boolean = false;
    let condition2: boolean = false;

    if (
      withdrawalInfo.recentDeposits &&
      withdrawalInfo.recentDeposits.length > 0
    ) {
      condition1 = true;
    }

    if ((withdrawalInfo.withdrawalBlock || 0) > 0) {
      condition2 = true;
    }

    if (condition1 && condition2) {
      return WithheldFundsType.PENDING_DEPOSITS_AND_WORKING_ORDERS;
    }

    if (condition1) {
      return WithheldFundsType.PENDING_DEPOSITS;
    }

    if (condition2) {
      return WithheldFundsType.WORKING_ORDERS;
    }

    return WithheldFundsType.NO_WITHOLDS;
  }, [withdrawalInfo]);

  const handleWithdrawal = async (accountId?: number) => {
    if (!transferApi) {
      throw new Error('Transfers not available');
    }

    if (!accountId) {
      throw new Error('Account not set');
    }

    if (!amount) {
      throw new Error('Amount not set');
    }

    setTransferLoading(true);

    transferApi
      .initiateWithdrawal({
        initiateWithdrawal: {
          accountId,
          amount,
        },
      })
      .then(() => {
        setTransferLoading(false);
        setSubmitted(true);
      })
      .catch((err) => {
        setTransferLoading(false);
        setTransferError(true);
      });
  };

  const handleWireTransfer = async (params: IOnWithdrawalFormSubmitParams) => {
    setTransferLoading(true);

    const accessToken = oktaAuth.getAccessToken();

    const intercepter = new CallInterceptor();

    const api = new TransferApi(
      new Configuration({
        basePath: config.basePath,
        accessToken,
        middleware: [intercepter],
      })
    );

    const num = Number.parseFloat(params.amount.slice(1));

    try {
      await api.initiateWireTransfer({
        initiateWireTransfer: {
          amount: num,
          recipient: {
            routingNumber: params.routing,
            accountNumber: params.account,
          },
          thirdParty: false,
        },
      });

      setSubmitted(true);

      setTransferLoading(false);
    } catch (err) {
      setTransferError(true);

      setTransferLoading(false);
    }
  };

  const location = useLocation();

  const isWireTransfer = useMemo(() => {
    return location.pathname === '/banking/withdrawal';
  }, [location.pathname]);

  const refreshPage = () => {
    window.location.reload();
  };

  const isDesktop = useMediaQuery('(min-width:775px)');

  const isMdUp = useMediaQuery('(min-width:1280px)');

  if (submitted) {
    return <Redirect to="/transfers/success/withdrawal" />;
  }

  return (
    <>
      {transerLoading && (
        <Box
          display={'flex'}
          height={isMdUp ? '100%' : 'calc(100vh - 53px)'}
          width={'100%'}
          justifyContent="center"
          alignItems="center"
        >
          <Box display="flex" flexDirection="column" alignItems="center">
            <TransferIcon />

            <Typography className={styles.transferTitle}>
              Working on your request
            </Typography>

            <CircularProgress size={24} />
          </Box>
        </Box>
      )}

      {(transferError || error) && (
        <Box
          display={'flex'}
          height={isMdUp ? '100%' : 'calc(100vh - 53px)'}
          width={'100%'}
          justifyContent="center"
          alignItems="center"
        >
          <Box
            display="flex"
            maxWidth={310}
            flexDirection="column"
            alignItems="center"
          >
            <ErrorIcon />

            <Typography className={styles.transferTitle}>Error</Typography>

            <div className={styles.mb12}>
              <Typography
                className={`${styles.fs12} ${styles.secondaryColor} ${styles.mb12}`}
              >
                {error ? error : 'Unable to process your request at this time'}
              </Typography>

              <Typography className={`${styles.fs12} ${styles.secondaryColor}`}>
                For more information contact support at:{' '}
              </Typography>

              <Typography className={styles.mb12}>
                <a className={styles.link} href="mailto:support@optionsai.com">
                  support@optionsai.com
                </a>
              </Typography>
            </div>

            <Button onClick={refreshPage}>
              <Typography
                className={`${styles.textButton} ${styles.fs12Important} ${styles.primaryColor}`}
              >
                Refresh Page
              </Typography>
            </Button>
          </Box>
        </Box>
      )}

      {!transerLoading && !transferError && (
        <Box
          py={3}
          px={isDesktop ? 5 : 3}
          display={isDesktop ? 'flex' : 'inline-block'}
          justifyContent={'space-between'}
          width={'100%'}
        >
          <div className={styles.accountInformation}>
            <Box pt={2} pb={isWireTransfer ? 2 : 3}>
              <Typography className={styles.pageTitle}>
                {isWireTransfer ? 'Wire Funds:' : 'ACH Withdraw:'}
              </Typography>

              <Typography
                className={`${styles.fs12} ${styles.secondaryColor} ${styles.fw700}`}
              >
                Available to Withdraw
              </Typography>

              <TextWithSkeleton
                width={'200px'}
                className={styles.availableWithdraw}
              >
                {withdrawalInfo?.total === undefined ||
                balanceLoading ? undefined : (
                  <Money>{withdrawalInfo?.total}</Money>
                )}
              </TextWithSkeleton>

              {isWireTransfer && (
                <Box mt={2}>
                  <Typography>
                    <a
                      href="https://disclosures.optionsai.com/disclosures/commission-and-fees#funds-and-account-transfer-fees"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.link}
                    >
                      <span>
                        <InfoOutlined className={styles.icon} />
                      </span>
                      Please note wire transfers incur fees
                    </a>
                  </Typography>
                </Box>
              )}
            </Box>

            <div className={styles.balanceBox}>
              {withdrawalInfo?.total === undefined || balanceLoading ? (
                <Box width={'100%'} display="flex" justifyContent={'center'}>
                  <CircularProgress color="primary" />
                </Box>
              ) : (
                <>
                  <Typography
                    className={`${isDesktop ? styles.fs12 : styles.fs11} ${
                      styles.primaryColor
                    } ${styles.fw600}`}
                  >
                    Balance Breakdown
                  </Typography>
                  <Box
                    display={'flex'}
                    alignItems="center"
                    justifyContent="space-between"
                    mt={isDesktop ? 1 : 0}
                  >
                    <Typography
                      className={`${styles.fs10} ${styles.secondaryColor} ${styles.fw700}`}
                    >
                      Cash on account:
                    </Typography>

                    <Typography
                      className={`${styles.fs10} ${styles.secondaryColor}`}
                    >
                      ${withdrawalInfo?.startDayCashAvailable}
                    </Typography>
                  </Box>
                  <Box my={isDesktop ? 2 : 1}>
                    <hr color="#828282" />
                  </Box>
                  <Typography
                    className={`${styles.fs10} ${styles.secondaryColor} ${
                      withheldFundsType === WithheldFundsType.NO_WITHOLDS
                        ? ''
                        : styles.fw700
                    }  ${
                      withheldFundsType !== WithheldFundsType.NO_WITHOLDS &&
                      (isDesktop || breakdownExpanded)
                        ? styles.mb12
                        : ''
                    }`}
                  >
                    {withheldFundsType === WithheldFundsType.NO_WITHOLDS
                      ? 'There are no holds on your account'
                      : 'Why are my funds not available to withdraw?'}
                  </Typography>
                  {withheldFundsType === WithheldFundsType.WORKING_ORDERS &&
                    (isDesktop || breakdownExpanded) && (
                      <>
                        <Typography
                          className={`${styles.fs10} ${styles.secondaryColor} ${styles.mb10}`}
                        >
                          Some or all of your funds are being withheld to fund
                          working orders or recently opened positions. You can
                          cancel working orders to make funds available or wait
                          till the next business day to withdraw.
                        </Typography>

                        <TextArrowLink
                          href={`${REACT_APP_TRADE}/orders`}
                          label="Your Orders"
                        />
                      </>
                    )}
                  {withheldFundsType === WithheldFundsType.PENDING_DEPOSITS &&
                    (isDesktop || breakdownExpanded) && (
                      <>
                        <Typography
                          className={`${styles.fs10} ${styles.secondaryColor} ${styles.mb10}`}
                        >
                          After funds are deposited via ACH, they are placed on
                          a mandatory 5 business day hold.
                        </Typography>

                        <Typography
                          className={`${styles.fs10} ${styles.secondaryColor} ${styles.mb12}`}
                        >
                          Thereafter, they are available for withdrawal. Funds
                          from closed transactions typically take 1 business day
                          to settle and may be withdrawn after settlement and/or
                          the hold period (whichever is later).
                        </Typography>

                        <Typography
                          className={`${styles.fs10} ${styles.secondaryColor} ${styles.mb10}`}
                        >
                          Most Recent Deposit:{' '}
                        </Typography>

                        <Box display={'flex'} justifyContent="space-between">
                          <Typography
                            className={`${styles.fs10} ${styles.secondaryColor}`}
                          >
                            {withdrawalInfo?.recentDeposits?.[0].processDate}
                          </Typography>

                          <Typography
                            className={`${styles.fs10} ${styles.secondaryColor}`}
                          >
                            <Money>
                              {withdrawalInfo?.recentDeposits?.[0].amount}
                            </Money>
                          </Typography>
                        </Box>
                      </>
                    )}
                  {withheldFundsType ===
                    WithheldFundsType.PENDING_DEPOSITS_AND_WORKING_ORDERS &&
                    (isDesktop || breakdownExpanded) && (
                      <>
                        <ul>
                          <li>
                            <>
                              <Typography
                                className={`${styles.fs10} ${styles.secondaryColor} ${styles.mb10}`}
                              >
                                After funds are deposited via ACH, they are
                                placed on a mandatory 5 business day hold.
                              </Typography>

                              <Typography
                                className={`${styles.fs10} ${styles.secondaryColor} ${styles.mb12}`}
                              >
                                Thereafter, they are available for withdrawal.
                                Funds from closed transactions typically take 1
                                business day to settle and may be withdrawn
                                after settlement and/or the hold period
                                (whichever is later).
                              </Typography>

                              <Typography
                                className={`${styles.fs10} ${styles.secondaryColor} ${styles.mb10}`}
                              >
                                Most Recent Deposit:{' '}
                              </Typography>

                              <Box
                                display={'flex'}
                                justifyContent="space-between"
                              >
                                <Typography
                                  className={`${styles.fs10} ${styles.secondaryColor}`}
                                >
                                  {
                                    withdrawalInfo?.recentDeposits?.[0]
                                      .processDate
                                  }
                                </Typography>

                                <Typography
                                  className={`${styles.fs10} ${styles.secondaryColor}`}
                                >
                                  <Money>
                                    {withdrawalInfo?.recentDeposits?.[0].amount}
                                  </Money>
                                </Typography>
                              </Box>
                            </>
                          </li>
                        </ul>

                        <Box width="100%" my={1.5} padding="0 10%">
                          <HorizontalRuleHint text="&" />
                        </Box>

                        <ul>
                          <li>
                            <>
                              <Typography
                                className={`${styles.fs10} ${styles.secondaryColor} ${styles.mb10}`}
                              >
                                Some or all of your funds are being withheld to
                                fund working orders. Please cancel your orders
                                to make funds available to withdraw.
                              </Typography>

                              <TextArrowLink
                                href={`${REACT_APP_TRADE}/orders`}
                                label="Your Orders"
                              />
                            </>
                          </li>
                        </ul>
                      </>
                    )}
                  {!isDesktop && (
                    <Box
                      width={'100%'}
                      display="flex"
                      justifyContent={
                        breakdownExpanded ? 'flex-end' : 'flex-start'
                      }
                    >
                      <Button
                        className={
                          breakdownExpanded ? undefined : styles.noPaddingLeft
                        }
                        onClick={toggleBreakdownExpanded}
                      >
                        <Typography
                          className={`${styles.textButton} ${styles.fs11} ${styles.primaryColor}`}
                        >
                          {breakdownExpanded ? 'Collapse' : 'Expand'}
                        </Typography>
                      </Button>
                    </Box>
                  )}{' '}
                </>
              )}
            </div>

            {isDesktop && (
              <Box mt={2} width="100%" maxWidth={'260px'}>
                <Typography
                  className={`${styles.fs11} ${styles.secondaryColor}`}
                >
                  Got questions? Contact support at:{' '}
                  <a
                    className={styles.link}
                    href="mailto:support@optionsai.com"
                  >
                    support@optionsai.com
                  </a>
                </Typography>
              </Box>
            )}
          </div>

          <div className={styles.form}>
            {isWireTransfer ? (
              <WithdrawalForm
                available={withdrawalInfo?.total}
                onSubmit={handleWireTransfer}
                loading={transerLoading}
              />
            ) : (
              <WithdrawForm
                banking={banking}
                handleSubmit={handleWithdrawal}
                withdrawalInfo={withdrawalInfo}
                setAmount={setAmount}
                amount={amount}
                loading={accountsLoading || balanceLoading}
              />
            )}
          </div>

          {!isDesktop && (
            <Box mt={2} width="100%" maxWidth={'260px'}>
              <Typography className={`${styles.fs11} ${styles.secondaryColor}`}>
                Got questions? Contact support at:{' '}
                <a className={styles.link} href="mailto:support@optionsai.com">
                  support@optionsai.com
                </a>
              </Typography>
            </Box>
          )}
        </Box>
      )}
    </>
  );
});

export default Withdraw;
