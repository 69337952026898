import TextField, {
  BaseTextFieldProps,
  TextFieldProps
} from "@material-ui/core/TextField";
import React, { FC, useRef } from "react";

import { makeStyles } from "@material-ui/core";
import { TextAlignProperty } from "csstype";

interface IProps {
  error?: boolean;
  warning?: boolean;
  positionCursorAtLast?: boolean;
  value?: string;
  textAlign?: TextAlignProperty;
  required?: boolean;
}

interface IUseStylesParams {
  disabled?: boolean;
  error?: boolean;
  warning?: boolean;
  textAlign?: TextAlignProperty;
}

const useStyles = ({
  disabled,
  error,
  warning,
  textAlign = "right"
}: IUseStylesParams) => {
  let outlineColor = "rgba(0, 0, 0, 0.23)";
  if (error) {
    outlineColor = "1px solid red !important";
  }

  if (warning) {
    outlineColor = "orange !important";
  }
  return makeStyles({
    inputSizing: {
      height: 40,
      width: "100%"
    },
    input: {
      fontSize: 16,
      padding: 2,
      textAlign,
      paddingRight: 12,
      paddingLeft: 12
    },
    disabledInput: {
      outline: "none",
      border: "none"
    },
    notchedOutline: {
      border: error ? outlineColor : ".75px solid #F0F0F0",
      outlineColor,
      borderRadius: 4
    }
  });
};

const DefaultInput: FC<IProps & TextFieldProps & BaseTextFieldProps> = ({
  type = "text",
  disabled,
  error,
  warning,
  ref,
  onChange,
  value,
  textAlign,
  positionCursorAtLast,
  ...inputProps
}) => {
  const componentStyles = useStyles({ disabled, error, warning, textAlign })();

  const refer: React.RefObject<HTMLInputElement> | null = useRef(null);

  const handleFocus = () => {
    const inputRef = refer.current;

    if (inputRef !== null && positionCursorAtLast) {
      setTimeout(() => {
        inputRef.selectionStart = value ? value.length + 1 : 0;

        inputRef.selectionEnd = value ? value.length + 1 : 0;
      }, 1);
    }
  };

  const positionCursorAtLastFunc = () => {
    const inputRef = refer.current;

    if (inputRef !== null) {
      setTimeout(() => {
        inputRef.selectionStart = value ? value.length + 1 : 0;

        inputRef.selectionEnd = value ? value.length + 1 : 0;
      }, 1);
    }
  };

  const handleChange = (e: any) => {
    if (onChange) {
      onChange(e);
    }
    if (positionCursorAtLast) {
      positionCursorAtLastFunc();
    }
  };

  return (
    <TextField
      value={value}
      {...inputProps}
      onChange={handleChange}
      inputRef={refer}
      className={componentStyles.inputSizing}
      InputProps={{
        classes: {
          input: `${componentStyles.input} ${componentStyles.inputSizing}`,
          notchedOutline: disabled
            ? componentStyles.disabledInput
            : componentStyles.notchedOutline,
          focused: componentStyles.notchedOutline
        }
      }}
      fullWidth={false}
      variant="outlined"
      type={type}
      onFocus={handleFocus}
    />
  );
};

export default DefaultInput;
