import React, { FC } from "react";

import { RouteComponentProps, withRouter } from "react-router";

import { Box, Button, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import ResponsiveLayout from "pages/ResponsiveLayout";

const WithdrawalFailed: FC<RouteComponentProps> = ({ history, location }) => {
  const handleBack = () => {
    history.replace("/banking");
  };

  return (
    <ResponsiveLayout
      pageTitle="Withdraw Funds"
      returnPageHandler={handleBack}
      returnPageTitle="Banking"
    >
      <Box pt={6}>
        <Box justifyContent="center" display="flex" width="100%">
          <Box
            bgcolor="#D63102"
            p={2}
            width={100}
            height={100}
            borderRadius="50%"
            justifyContent="center"
            display="flex"
            alignItems="center"
            color="#fff"
          >
            <Close style={{ fontSize: 72 }} />
          </Box>
        </Box>

        <Box width="80%" margin="0 auto">
          <Box pt={4}>
            <Typography variant="h6" align="center">
              Withdrawal Failed.
            </Typography>
          </Box>

          <Box pt={4}>
            <Typography variant="h6" align="center">
              Please contact support@optionsai.com for help.
            </Typography>
          </Box>
        </Box>

        <Box pt={6} width="100%" display="flex" justifyContent="center">
          <Box width="40%">
            <Button
              onClick={handleBack}
              fullWidth={true}
              size="large"
              variant="contained"
              color="primary"
            >
              Close
            </Button>
          </Box>
        </Box>
      </Box>
    </ResponsiveLayout>
  );
};

export default withRouter(WithdrawalFailed);