import React, { FC } from 'react';

import Typography, { TypographyProps } from '@material-ui/core/Typography';
import Skeleton from './Skeleton';

interface ITextWithSkeletonProps extends TypographyProps {
  width?: number | string;
  maxWidth?: number | string;
  numOfSkeletonPlaceholders?: number;
}

const TextWithSkeleton: FC<ITextWithSkeletonProps> = React.memo(
  ({
    children,
    width = '100%',
    numOfSkeletonPlaceholders = 1,
    maxWidth,
    ...props
  }) => {
    return (
      <Typography {...props}>
        {children === undefined ? (
          <Skeleton
            count={numOfSkeletonPlaceholders}
            maxWidth={maxWidth}
            data-testid="text-skeleton"
            width={width}
          />
        ) : (
          children
        )}
      </Typography>
    );
  }
);

export default TextWithSkeleton;
