/* eslint-disable @typescript-eslint/no-unused-vars */
import { observable, computed, action } from 'mobx';

import { LinkedAccount, Transfer } from '@optionsai/oai-api-js';

import compareDesc from 'date-fns/compareDesc';
import parseISO from 'date-fns/parseISO';

class Banking {
  @observable
  public linkedAccounts: LinkedAccount[] = [];

  @observable
  private transferHistory: Transfer[] = [];

  @computed
  public get canWithdraw() {
    return this.hasLinkedAccounts;
  }

  @computed
  public get canDeposit() {
    return this.hasLinkedAccounts;
  }

  @computed
  public get hasLinkedAccounts() {
    return this.linkedAccounts.length > 0;
  }

  @computed
  public get hasTransferHistory() {
    return this.transferHistory.length > 0;
  }

  @computed
  public get transfers() {
    return this.transferHistory
      .slice()
      .sort((a, b) =>
        compareDesc(parseISO(a.createdAt), parseISO(b.createdAt))
      );
  }

  @action
  public setLinkedAccounts(accounts: LinkedAccount[]) {
    this.linkedAccounts = accounts.filter((a) => a.status !== 'CANCELED');
  }

  @action
  public setTransferHistory(transfers: Transfer[]) {
    this.transferHistory = transfers;
  }
}

export default Banking;
