import React, { FunctionComponent } from "react";

// components
import { Typography, Grid, Box, IconButton, Divider } from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";

interface IPageHeaderProps {
  header?: string;
  children?: React.ReactNode;
  goBack?: () => void;
}

const PageHeader: FunctionComponent<IPageHeaderProps> = ({
  header,
  children,
  goBack
}) => {
  return (
    <Box>
      <Grid container={true} alignItems="center" justify="space-between">
        <Grid>
          <Box ml={2.5}>
            <IconButton
              size="small"
              style={{ backgroundColor: "#F5F5F5" }}
              onClick={goBack}
            >
              <ChevronLeft />
            </IconButton>
          </Box>
        </Grid>

        <Box py={2}>
          <Typography align="center" variant="h3">
            {header}
          </Typography>
        </Box>

        <Box width={50} height={2} />
      </Grid>

      {children}
      <Divider />
    </Box>
  );
};

export default PageHeader;
