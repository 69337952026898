import React, { FC } from "react";

import { Box, Grid, IconButton, ListItem, Typography } from "@material-ui/core";
import { ArrowRight } from "@material-ui/icons";

interface IUpdateListItemProps {
  secondaryText?: string;
  onClick: () => void;
  primaryText?: string;
}

const UpdateListItem: FC<IUpdateListItemProps> = ({
  secondaryText,
  onClick,
  primaryText
}) => {
  return (
    <ListItem button={true} divider={true}>
      <Grid container={true} justify="space-between">
        <Box maxWidth="75%" p={1}>
          <Typography variant="h5">{primaryText}</Typography>

          <Typography variant="h6" color="textSecondary">
            {secondaryText}
          </Typography>
        </Box>

        <IconButton disabled={true}>
          <ArrowRight />
        </IconButton>
      </Grid>
    </ListItem>
  );
};

export default UpdateListItem;
