import { useOktaAuth } from '@okta/okta-react';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

// import { AccountApi, Configuration } from "@optionsai/oai-api-js";

import { Box, List, ListItem, Typography } from '@material-ui/core';

import UpdateListItem from 'components/Account/UpdateListItem/UpdateListItem';

import { AccountApi, Configuration, Account } from '@optionsai/oai-api-js';
import config from 'core/api/config';
import Skeleton from 'react-loading-skeleton';
import ResponsiveLayout from 'pages/ResponsiveLayout';
import CallInterceptor from 'core/api/Interceptor';
import useReturn from 'hooks/useReturn';
import useParseToken from 'hooks/useParseToken';

interface IProps extends RouteComponentProps { }

const UpdateAccount: FC<IProps> = ({ history }) => {
  const { oktaAuth } = useOktaAuth();
  const { handleReturn } = useReturn();
  const parsedToken = useParseToken(oktaAuth.getAccessToken());


  console.log('token', parsedToken)

  const [accountData, setAccountData] = useState<Account | undefined>();

  const handleEmailSelection = () => {
    history.push('/settings/account/email', { from: true });
  };

  const handlePhoneNumberSelection = () => {
    history.push('/settings/account/phone', { from: true });
  };

  const handleAddressSelection = () => {
    history.push('/settings/account/address', { from: true });
  };

  const getAccount = async () => {
    try {
      const accessToken = oktaAuth.getAccessToken();

      const intercepter = new CallInterceptor();

      const api = new AccountApi(
        new Configuration({
          basePath: config.basePath,
          accessToken,
          middleware: [intercepter],
        })
      );

      const data = await api.getAccount();

      setAccountData(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAccount();

    // eslint-disable-next-line
  }, []);

  const firstName = useMemo(() => {
    if (!accountData) {
      return;
    }

    return accountData.firstName;
  }, [accountData]);

  const lastName = useMemo(() => {
    if (!accountData) {
      return;
    }

    return accountData.lastName;
  }, [accountData]);

  const accountNumber = useMemo(() => {
    if (!accountData) {
      return;
    }

    return accountData.accountNumber;
  }, [accountData]);

  const addressString = useMemo(() => {
    if (!accountData || !accountData.address) {
      return;
    }

    const address = accountData.address;

    return `${address.streetAddress} ${address.city}, ${address.state} ${address.zipCode}`;
  }, [accountData]);


  const emailDomain = parsedToken?.sub?.split('@')?.[1] || '@example.com'

  return (
    <ResponsiveLayout
      pageTitle="Update Account"
      returnPageHandler={handleReturn}
      returnPageTitle="Profile"
    >
      <List component="nav">
        <ListItem>
          <Box p={1} display="flex" alignItems="center">
            <Box>
              <Typography
                variant="h3"
                gutterBottom={true}
                color="textSecondary"
              >
                {firstName || <Skeleton width={100} />}{' '}
                {lastName || <Skeleton width={100} />}
              </Typography>

              <Typography variant="h3" color="textSecondary">
                {accountNumber || <Skeleton width={150} />}
              </Typography>
            </Box>
          </Box>
        </ListItem>

        <UpdateListItem
          onClick={handleEmailSelection}
          primaryText="Email"
          secondaryText={`******@${emailDomain}`}
        />

        <UpdateListItem
          onClick={handlePhoneNumberSelection}
          primaryText="Phone"
          secondaryText="*******"
        />

        <UpdateListItem
          onClick={handleAddressSelection}
          primaryText="Address"
          secondaryText={addressString}
        />
      </List>

      <Box position="absolute" bottom={8} left={0} width="100%" p={2}>
        <Typography gutterBottom={true} variant="body2" align="center">
          To edit your account details (email, address etc) please contact
          support@optionsai.com. We will respond with instructions. For
          information on how to close your account please email
          support@optionsai.com
        </Typography>
      </Box>
    </ResponsiveLayout>
  );
};

export default withRouter(UpdateAccount);
