import React, { FC } from 'react';

import { RouteComponentProps, withRouter } from 'react-router';

import ResponsiveLayout from 'pages/ResponsiveLayout';

import Deposit from 'containers/Banking/Deposit';

const DepositPage: FC<RouteComponentProps> = ({ history }) => (
  <>
    <ResponsiveLayout
      pageTitle="Deposit"
      returnPageTitle="Banking"
      returnPageHandler={history.goBack}
    >
      <Deposit />
    </ResponsiveLayout>
  </>
);

export default withRouter(DepositPage);
