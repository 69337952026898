import React, { FC, useState, useCallback, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';

import { Box, Grid, Typography, Divider } from '@material-ui/core';

import AccountDescription from 'containers/Account/AccountDescription';

import config from 'core/api/config';

import {
  Configuration,
  AccountApi,
  AccountBalance,
  UserBalance,
} from '@optionsai/oai-api-js';

import { ChevronRight } from '@material-ui/icons';

import theme from '@optionsai/oai-mui-theme';
import Skeleton from 'react-loading-skeleton';

import { toDollarValue } from 'components/UI/FormatNum/Dollar/DollarValue';

import ResponsiveLayout from 'pages/ResponsiveLayout';

import CallInterceptor from 'core/api/Interceptor';
import useReturn from 'hooks/useReturn';
import { useOktaAuth } from '@okta/okta-react';
import Cookies, { CookieAttributes } from 'js-cookie';

import { getEnvVar, isLocalHost } from '@optionsai/oai-common-js';
import { SimpleStorage } from '@okta/okta-auth-js';
import { REACT_APP_ID } from 'core/constants';

const uat = getEnvVar('REACT_APP_AUTH_UAT', 'false') === 'true';

const domain = isLocalHost()
  ? 'localhost'
  : uat
    ? 'uat.options.ai'
    : 'options.ai';

const cookieOptions = {
  domain,
  path: '/',
  secure: !isLocalHost(),
} as CookieAttributes;

const tokenStorage = {
  getItem(key) {
    return Cookies.get(key);
  },
  setItem(key, value) {
    Cookies.set(key, value, cookieOptions);
  },
} as SimpleStorage;



const AccountSummary: FC = () => {
  const { handleReturn } = useReturn();
  const { oktaAuth } = useOktaAuth();

  const [accountBalance, setAccountBalance] = useState<AccountBalance>();
  const [tradingLimits, setTradingLimits] = useState<UserBalance>();


  const fingerprint = tokenStorage.getItem('fingerprint')
  console.log('fingerprint', fingerprint)

  const getAccountData = useCallback(async () => {
    const accessToken = oktaAuth.getAccessToken();

    const callInterceptor = new CallInterceptor();

    const fingerprint = tokenStorage.getItem('fingerprint')

    if (!fingerprint) {
      oktaAuth.signOut()
      window.location.href = REACT_APP_ID
      return
    }

    const hashedFingerPrint = JSON.parse(fingerprint)

    const api = new AccountApi(
      new Configuration({
        basePath: config.basePath,
        accessToken,
        middleware: [callInterceptor],
        headers: {
          'X-Fingerprint': hashedFingerPrint
        }
      })
    );

    try {
      const accountBalanaceCall = api.getAccountBalance();
      const tradingLimitsCall = api.getAccountTradingLimit();

      const accountBalanceRes = await accountBalanaceCall;
      const tradingLimitsRes = await tradingLimitsCall;

      setAccountBalance(accountBalanceRes);
      setTradingLimits(tradingLimitsRes);
    } catch (err) {
      console.log(err);
    }
  }, [oktaAuth]);

  useEffect(() => {
    getAccountData();
  }, [getAccountData]);

  return (
    <ResponsiveLayout
      pageTitle="Account Summary"
      returnPageHandler={handleReturn}
      returnPageTitle="Account"
    >
      <Box p={1}>
        <Box
          mt={2}
          bgcolor="#fff"
          borderRadius={4}
          border=".75px solid #EBEBEB"
        >
          <Box pt={2} px={1}>
            <Box px={1}>
              <Grid container={true} justify="space-between">
                <Typography style={{ fontWeight: 600 }} variant="h2">
                  Account Value
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  {accountBalance?.balance !== undefined ? (
                    toDollarValue(accountBalance.balance)
                  ) : (
                    <Skeleton width={55} />
                  )}
                </Typography>
              </Grid>
            </Box>
          </Box>

          <Box pt={1} p={2}>
            <Typography variant="body1">
              The combined value of your positions, settled and unsettled funds.
            </Typography>
          </Box>
        </Box>

        <Box
          mt={2}
          bgcolor="#fff"
          borderRadius={4}
          border=".75px solid #EBEBEB"
        >
          <Box pt={2} px={1}>
            <Box px={1}>
              <Grid container={true} justify="space-between">
                <Typography style={{ fontWeight: 600 }} variant="h2">
                  Buying Power
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  {tradingLimits?.cashOnAccount !== undefined ? (
                    toDollarValue(tradingLimits.cashOnAccount)
                  ) : (
                    <Skeleton width={55} />
                  )}
                </Typography>
              </Grid>
            </Box>
          </Box>

          <Box pt={1} p={2}>
            <Typography variant="body1">
              Funds available to open new positions. Buying Power will be
              affected by open orders and unsettled funds.
            </Typography>
          </Box>
        </Box>

        <Box
          bgcolor="#fff"
          borderRadius={4}
          border=".75px solid #EBEBEB"
          mt={2}
        >
          <Link
            to={{
              state: {
                from: true,
              },
              pathname: '/banking',
            }}
            style={{ textDecoration: 'none' }}
          >
            <Box py={1} pl={2} pr={1.5}>
              <Grid
                container={true}
                justify="space-between"
                alignItems="center"
              >
                <Grid item={true} xs={6}>
                  <Typography color="textSecondary" variant="body1">
                    Add Funds
                  </Typography>
                </Grid>

                <Grid container={true} justify="flex-end" item={true} xs={6}>
                  <ChevronRight
                    style={{ color: theme.palette.text.secondary }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Link>

          <Divider />

          <Link
            style={{ textDecoration: 'none' }}
            to={{
              state: {
                from: true,
              },
              pathname: '/banking/withdraw',
            }}
          >
            <Box py={1} pl={2} pr={1.5}>
              <Grid
                container={true}
                justify="space-between"
                alignItems="center"
              >
                <Grid item={true} xs={6}>
                  <Typography color="textSecondary" variant="body1">
                    Withdraw Funds
                  </Typography>
                </Grid>
                <Grid container={true} justify="flex-end" item={true} xs={6}>
                  <ChevronRight
                    style={{ color: theme.palette.text.secondary }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Link>
        </Box>

        <AccountDescription />
      </Box>
    </ResponsiveLayout>
  );
};

export default withRouter(AccountSummary);
