export default (optionLevel: number) => {
  var trades = [
    'Long Stock'
  ];

  if (optionLevel > 0) {
    trades.push('Buy Writes');
  }

  if (optionLevel > 1) {
    trades.push('Long Calls');
    trades.push('Long Puts')
  }

  if (optionLevel > 2) {
    trades.push('Long Vertical Spreads');
    trades.push('Short Vertical Spreads');
    trades.push('Short Iron Butterflies');
    trades.push('Short Iron Condors');
  }

  return trades;
}
