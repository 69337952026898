import React, { FC } from 'react';

interface IProps {
  className?: string;
  children?: number;
  showDecimals?: boolean;
  showSymbol?: boolean;
}

const Money: FC<IProps> = ({
  children = 0.0,
  showSymbol = true,
  showDecimals = true,
  className = 'Price',
}) => {
  const price: string = children.toLocaleString('en', {
    currency: showSymbol ? 'USD' : undefined,
    maximumFractionDigits: showDecimals ? 2 : 0,
    minimumFractionDigits: showDecimals ? 2 : 0,
    style: showSymbol ? 'currency' : undefined,
  });

  return (
    <span data-testid="Money" className={className}>
      {price}
    </span>
  );
};

Money.displayName = 'Money';

export default Money;
