import React, { FC, useContext, useMemo, forwardRef } from 'react';

import { Link, LinkProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import BankingContext from 'contexts/BankingContext';
import TransferPendingAlert from './TransferPendingAlert';

const InitiateTransfer: FC = observer(() => {
  const banking = useContext(BankingContext);

  const canWithdraw = banking.canWithdraw;
  const canDeposit = banking.canDeposit;

  const depositLink = useMemo(
    () =>
      forwardRef<any, Omit<LinkProps, 'to'>>((props, ref) => (
        <Link innerRef={ref} to="/banking/deposit" {...props} />
      )),
    []
  );

  const withdrawLink = useMemo(
    () =>
      forwardRef<any, Omit<LinkProps, 'to'>>((props, ref) => (
        <Link innerRef={ref} to="/banking/withdraw" {...props} />
      )),
    []
  );

  return (
    <Box padding={2}>
      <Typography variant="h3" gutterBottom={true}>
        Transfer
      </Typography>

      <Typography color="textSecondary" variant="body1" gutterBottom={true}>
        Transfer money from/to your linked account
      </Typography>

      <Box mt={2}>
        <TransferPendingAlert />
      </Box>

      <List>
        <Box marginY={1}>
          <Paper variant="outlined">
            <ListItem
              button={true}
              component={depositLink}
              disabled={!canDeposit}
            >
              <ListItemText>Deposit</ListItemText>
              <ListItemSecondaryAction>
                <ChevronRightIcon />
              </ListItemSecondaryAction>
            </ListItem>
          </Paper>
        </Box>

        <Box marginY={1}>
          <Paper variant="outlined">
            <ListItem
              button={true}
              component={withdrawLink}
              disabled={!canWithdraw}
            >
              <ListItemText>Withdraw</ListItemText>
              <ListItemSecondaryAction>
                <ChevronRightIcon />
              </ListItemSecondaryAction>
            </ListItem>
          </Paper>
        </Box>
      </List>

      <Typography
        style={{ textDecoration: 'none' }}
        color="primary"
        to="/banking-wires"
        component={Link}
      >
        Use wire transfer instead
      </Typography>
    </Box>
  );
});

export default InitiateTransfer;
