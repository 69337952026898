import React, { FC } from 'react';

import Box from '@material-ui/core/Box';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Money from 'components/UI/Money';

import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { Transfer } from '@optionsai/oai-api-js';

interface IProps {
  transfer: Transfer;
}

const marginBottom = { marginBottom: 12 };

const TransferItem: FC<IProps> = ({ transfer }) => {
  const type =
    transfer?.direction === 'INCOMING' ? 'Deposit from' : 'Withdrawal to';

  const destination = `${transfer?.linkedAccount?.name || ''} - ${
    transfer?.linkedAccount?.accountNumber || ''
  }`;

  return (
    <ExpansionPanel style={marginBottom}>
      <ExpansionPanelSummary>
        <Box flexBasis="50%">
          <Typography variant="h5">{type} bank</Typography>
          <Typography>
            {transfer.updatedAt &&
              format(parseISO(transfer.updatedAt), 'MMM do yyyy hh:mm:ss a')}
          </Typography>
        </Box>
        <Box flexBasis="50%">
          <Typography variant="h4" align="right">
            <Money>{transfer.amount}</Money>
          </Typography>
        </Box>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Box width="100%">
          <Box paddingBottom={3}>
            <Grid container={true}>
              <Grid item={true} xs={6}>
                <Typography variant="body2">Transfer Initiated</Typography>
                <Typography variant="body2" color="textSecondary">
                  {format(
                    parseISO(transfer.createdAt),
                    'MMM do yyyy hh:mm:ss a'
                  )}
                </Typography>
              </Grid>
              <Grid item={true} xs={6}>
                <Typography variant="body2">Status</Typography>
                <Typography variant="body2" color="textSecondary">
                  {transfer.status}
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Box paddingBottom={3}>
            <Grid container={true}>
              <Grid item={true} xs={6}>
                <Typography variant="body2">Destination Account</Typography>
                <Typography variant="body2" color="textSecondary">
                  {destination}
                </Typography>
              </Grid>
              <Grid item={true} xs={6}>
                <Typography variant="body2">Last Updated</Typography>
                <Typography variant="body2" color="textSecondary">
                  {transfer.updatedAt &&
                    format(
                      parseISO(transfer.updatedAt),
                      'MMM do yyyy hh:mm:ss a'
                    )}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default React.memo(TransferItem);
