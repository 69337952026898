import React, { createContext, FC, useContext } from "react";

import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Switch,
  Typography
} from "@material-ui/core";
import Form from "containers/Form";
import useApiCall from "hooks/useApiCall";
import { observer } from "mobx-react-lite";
import { RouteComponentProps, withRouter } from "react-router";
import ServicesStore from "stores/Services";
import ResponsiveLayout from "pages/ResponsiveLayout";

interface IServicesProps extends RouteComponentProps {}

const context = createContext(new ServicesStore());

const Services: FC<IServicesProps> = observer(({ history, location }) => {
  const services = useContext(context);

  const handleBack = () => {
    if (location.state && location.state.from) {
      history.goBack();
      return;
    }

    history.replace("/settings");
  };

  const setHouseholdingServices = (e: any, value: boolean) => {
    services.householdingServices.set(value);
  };

  const setDividenReinvestment = (e: any, value: boolean) => {
    services.dividenReinvestment.set(value);
  };

  const setDirectCommunication = (e: any, value: boolean) => {
    services.directCommunication.set(value);
  };

  const { makeCall, loading, errMsg } = useApiCall(() => {}, handleBack, []);

  return (
    <ResponsiveLayout
      pageTitle="Services"
      returnPageTitle="Investment Profile"
      returnPageHandler={handleBack}
    >
      <Box p={1.5} pt={3}>
        <Form disabled onSubmit={makeCall} loading={loading}>
          <Typography gutterBottom={true} variant="h6">
            <Box fontWeight={600}>
              {servicesContent.freeHouseholding.header}
            </Box>
          </Typography>
          <Typography variant="body1">
            {servicesContent.freeHouseholding.description}
          </Typography>

          <FormControl margin="normal">
            <FormControlLabel
              control={
                <Switch
                  disabled
                  onChange={setHouseholdingServices}
                  value={services.householdingServices.value}
                  color="primary"
                  checked={services.householdingServices.value}
                />
              }
              label={
                services.householdingServices.value
                  ? servicesContent.freeHouseholding.answer.yes
                  : servicesContent.freeHouseholding.answer.no
              }
            />
          </FormControl>

          <Box marginTop={2}>
            <Typography gutterBottom={true} variant="h6">
              <Box fontWeight={600}>
                {servicesContent.freeDividendReinvestment.header}
              </Box>
            </Typography>
            <Typography variant="body1">
              {servicesContent.freeDividendReinvestment.description}
            </Typography>

            <FormControl margin="normal">
              <FormControlLabel
                control={
                  <Switch
                    disabled
                    onChange={setDividenReinvestment}
                    value={services.dividenReinvestment.value}
                    checked={services.dividenReinvestment.value}
                    color="primary"
                  />
                }
                label={
                  services.dividenReinvestment.value
                    ? servicesContent.freeDividendReinvestment.answer.yes
                    : servicesContent.freeDividendReinvestment.answer.no
                }
              />
            </FormControl>
          </Box>

          <Box marginTop={2}>
            <Typography gutterBottom={true} variant="h6">
              <Box fontWeight={600}>
                {servicesContent.directCommunication.header}
              </Box>
            </Typography>
            <Typography variant="body1">
              {servicesContent.directCommunication.description}
            </Typography>

            <FormControl margin="normal">
              <FormControlLabel
                control={
                  <Switch
                    disabled={true}
                    onChange={setDirectCommunication}
                    value={services.directCommunication.value}
                    checked={services.directCommunication.value}
                    color="primary"
                  />
                }
                label={
                  services.directCommunication.value
                    ? servicesContent.directCommunication.answer.yes
                    : servicesContent.directCommunication.answer.no
                }
              />
            </FormControl>

            <FormHelperText>{errMsg}</FormHelperText>

            <Box left={0} width="100%" p={2}>
              <Typography variant="body2" align="center">
                To edit your account details (email, address etc) please contact
                support@optionsai.com. We will respond with instructions. Thank
                you.
              </Typography>
            </Box>
          </Box>
        </Form>
      </Box>
    </ResponsiveLayout>
  );
});

export const servicesContent = {
  header: `Services`,
  freeHouseholding: {
    header: `Free Householding`,
    description: `The householding service combines mailing of account statements,
    tax-related statements, proxies, prospectuses, annual reports, and
    other eligible documents for accounts within your household into one
    envelope.`,
    answer: {
      yes: `I want to utillize the householding service. (This option requires a Householding Authorization form to be completed.`,
      no: `I do not want to household this new account with any of my other accounts.`
    }
  },
  freeDividendReinvestment: {
    header: `Free Dividend Reinvestment`,
    description: `Select whether or not you would like to have your dividends reinvested
    on all eligible securities. You can always change your selection later
    by calling your investment representative.`,
    answer: {
      yes: "Reinvest dividens on ALL eligible securities.",
      no: `Please do not reinvesst any dividends. Pay dividends in cash to my money market account.`
    }
  },
  directCommunication: {
    header: `Direct Communication Rule 14b-1(c)`,
    description: `Rule 14b-1(c) of the Securities Exchange Act, unless you object,
    requires us to disclose to an issuer, upon its request, the names,
    addresses, and securities positions of our customers who are
    beneficial owners of the issuer’s securities held by us in nominee
    name. The issuer would be permitted to use your name and other related
    information for corporation communication only. If you object to this
    disclosure check the box below.`,
    answer: {
      yes: `Yes, I object`,
      no: `No, I do not object`
    }
  }
};

export default withRouter(Services);
