const LOCAL_DOMAINS = ['localhost', '127.0.0.1', ''];

/**
 * Determines if environment is local host
 *
 * Checks for `localhost`, `127.0.0.1` or empty `window.location.hostname`
 */
const isLocalHost = () => LOCAL_DOMAINS.includes(window.location.hostname);

export default isLocalHost;
