import { useOktaAuth } from '@okta/okta-react';
import { Configuration, Transfer, TransferApi } from '@optionsai/oai-api-js';
import { useCallback, useEffect, useMemo, useState } from 'react';
import config from 'core/api/config';
import { compareDesc, parseISO } from 'date-fns';
import {
  TabName,
  TransferStatusEnum,
} from 'containers/Banking/TransferHistory';

export type TransferByType = {
  [key in TabName]: Transfer[];
};

const useTransferHistory = () => {
  const { oktaAuth } = useOktaAuth();

  const [transferApi, setTransferApi] = useState<TransferApi>();
  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState(false);
  const [transfers, setTransfers] = useState<Transfer[]>([]);

  useEffect(() => {
    const accessToken = oktaAuth.getAccessToken();

    try {
      if (!accessToken) {
        throw new Error('Needs access token');
      }

      const configuration = new Configuration({
        basePath: config.basePath,
        accessToken,
      });

      const api = new TransferApi(configuration);

      setTransferApi(api);
    } catch (e) {
      setError(e as any);
    }
  }, [oktaAuth]);

  const handleGetTransferHistory = useCallback(() => {
    setLoading(true);

    if (!transferApi) {
      return;
    }

    transferApi
      .getTransferHistory()
      .then((transfers) =>
        setTransfers(
          transfers.sort((a, b) =>
            compareDesc(parseISO(a.createdAt), parseISO(b.createdAt))
          )
        )
      )
      .catch((err) => setError(err))
      .finally(() => setLoading(false));
  }, [transferApi]);

  useEffect(() => {
    handleGetTransferHistory();
  }, [handleGetTransferHistory]);

  const [transfersByStatus, setTransfersByStatus] = useState<TransferByType>({
    Pending: [],
    Held: [],
    Complete: [],
  });

  useEffect(() => {
    const _transfersByStatus: TransferByType = {
      Pending: [],
      Held: [],
      Complete: [],
    };

    transfers.forEach((t) => {
      switch (t.status) {
        case TransferStatusEnum.COMPLETE:
        case TransferStatusEnum.CANCELED:
        case TransferStatusEnum.REJECTED: {
          _transfersByStatus.Complete.push(t);
          break;
        }

        case TransferStatusEnum.PENDING:
        case TransferStatusEnum.FUNDS_POSTED:
        case TransferStatusEnum.PENDING_PRINTING:
        case TransferStatusEnum.CANCEL_PENDING:
        case TransferStatusEnum.CANCEL_REQUESTED: {
          _transfersByStatus.Pending.push(t);
          break;
        }

        case TransferStatusEnum.BEING_HELD:
        case TransferStatusEnum.FUNDS_HELD: {
          _transfersByStatus.Held.push(t);
          break;
        }
      }
    });

    setTransfersByStatus(_transfersByStatus);
  }, [transfers]);

  const hasPending = useMemo(
    () => transfersByStatus.Pending.length > 0,
    [transfersByStatus]
  );

  return {
    transferApi,
    error,
    loading,
    transfers,
    refetch: handleGetTransferHistory,
    transfersByStatus,
    hasPending,
  };
};

export default useTransferHistory;
