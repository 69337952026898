import React, { FC, useCallback, useEffect, useState } from 'react';

import {
  List,
  Box,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  NativeSelect,
  ButtonGroup,
  Button,
} from '@material-ui/core';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';

import Money from 'components/UI/Money';
import TextWithSkeleton from 'components/UI/Loading/TextWithSkeleton';
import { WithdrawalInfo } from 'containers/Banking/Withdraw';
import Banking from 'stores/Banking';

interface Params {
  loading?: boolean;
  withdrawalInfo?: WithdrawalInfo;
  handleSubmit: (accountId?: number) => void;
  banking: Banking;
  amount?: number;
  setAmount: (amount?: number) => void;
}

const WithdrawForm: FC<Params> = React.memo(
  ({
    loading = false,
    withdrawalInfo,
    handleSubmit,
    banking,
    amount,
    setAmount,
  }) => {
    const [confirmed, setConfirmed] = useState(false);
    const [accountId, setAccountId] = useState<number>();

    const disabled =
      !amount ||
      amount <= 0 ||
      !accountId ||
      loading ||
      !withdrawalInfo?.total ||
      amount > withdrawalInfo?.total;

    useEffect(() => {
      if (!banking.linkedAccounts) {
        return;
      }

      if (banking.linkedAccounts.length === 0) {
        return;
      }

      setAccountId(banking.linkedAccounts[0].id);
    }, [banking.linkedAccounts]);

    const selectedAccount = banking.linkedAccounts.find(
      (a) => a.id === accountId
    );

    const options = banking.linkedAccounts.map((a) => ({
      value: a.id,
      label: `${a.name} (${a.accountNumber})`,
    }));

    const onCancel = useCallback(async () => setConfirmed(false), []);

    const onAccountSelect = useCallback(
      async (event: React.ChangeEvent<HTMLSelectElement>) =>
        setAccountId(Number.parseInt(event.target.value)),
      []
    );

    const onAmountChange = useCallback(
      async (event: React.ChangeEvent<HTMLInputElement>, value: number) =>
        setAmount(value),
      [setAmount]
    );

    const onSubmit = async () => {
      if (!confirmed) {
        setConfirmed(true);

        return;
      }

      handleSubmit(accountId);
    };

    return (
      <>
        <List>
          <Box paddingY={1}>
            <ListItem disableGutters={true}>
              <ListItemText
                primaryTypographyProps={{
                  variant: 'h4',
                }}
              >
                From
              </ListItemText>
              <ListItemSecondaryAction>
                <TextWithSkeleton width={200} variant="h4">
                  {loading ? undefined : 'Options AI - 3AI05XXXX'}
                </TextWithSkeleton>
              </ListItemSecondaryAction>
            </ListItem>
          </Box>

          <Box paddingY={1}>
            <ListItem disableGutters={true}>
              <ListItemText
                primaryTypographyProps={{
                  variant: 'h4',
                }}
              >
                To
              </ListItemText>
              <ListItemSecondaryAction>
                {loading ? (
                  <TextWithSkeleton variant="h4" width={200} />
                ) : (
                  <>
                    {confirmed ? (
                      <Typography variant="h4">
                        {selectedAccount?.name} -{' '}
                        {selectedAccount?.accountNumber}
                      </Typography>
                    ) : (
                      <NativeSelect onChange={onAccountSelect}>
                        {options.map((o) => (
                          <option key={o.value} value={o.value}>
                            {o.label}
                          </option>
                        ))}
                      </NativeSelect>
                    )}
                  </>
                )}
              </ListItemSecondaryAction>
            </ListItem>
          </Box>

          <Box paddingY={1}>
            <ListItem disableGutters={true}>
              <ListItemText
                primaryTypographyProps={{
                  variant: 'h4',
                }}
              >
                Amount
              </ListItemText>
              <ListItemSecondaryAction>
                {confirmed ? (
                  <Typography variant="h4">
                    <Money>{amount}</Money>
                  </Typography>
                ) : (
                  <CurrencyTextField
                    autoFocus={true}
                    variant="outlined"
                    value={amount}
                    minimumValue="0"
                    onChange={loading ? undefined : onAmountChange}
                  />
                )}
              </ListItemSecondaryAction>
            </ListItem>
          </Box>
        </List>

        <Box mt={2}>
          <ButtonGroup
            disableElevation={true}
            fullWidth={true}
            variant="contained"
          >
            {confirmed && (
              <Button size="large" variant="contained" onClick={onCancel}>
                Cancel
              </Button>
            )}
            <Button
              size="large"
              color="primary"
              onClick={onSubmit}
              disabled={disabled}
            >
              {confirmed ? 'Submit' : 'Review'}
            </Button>
          </ButtonGroup>
        </Box>
      </>
    );
  }
);

export default WithdrawForm;
