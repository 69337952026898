import { Box, Button, Typography } from '@material-ui/core';
import CurrencyInput from 'components/UI/Inputs/CurrencyInput';
import DefaultInput from 'components/UI/Inputs/DefaultInput';
import { IOnWithdrawalFormSubmitParams } from 'pages/Banking/Wire/Withdrawal';
import React, { ChangeEvent, FC, useMemo, useState } from 'react';

interface IWithdrawalFormProps {
  onSubmit?: (params: IOnWithdrawalFormSubmitParams) => void;
  available?: number;
  loading?: boolean;
}

const WithdrawalForm: FC<IWithdrawalFormProps> = ({
  onSubmit,
  available,
  loading,
}) => {
  const [amount, setAmount] = useState<string>('');

  const onAmountChange = (e: any, currencyValue: any) => {
    setAmount(currencyValue);
  };

  const memoizedError = useMemo(() => {
    if (amount && available !== undefined) {
      const strVal = amount.replace('$', '');
      const numVal = Number.parseFloat(strVal);

      return numVal > available;
    }
    return false;
  }, [amount, available]);

  const [routing, setRouting] = useState('');
  const [accountNum, setAccountNum] = useState('');

  const handleRoutingChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRouting(e.target.value);
  };

  const handleAccountNumChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAccountNum(e.target.value);
  };

  const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!accountNum || !routing || !amount || !onSubmit) {
      return;
    }

    onSubmit({ account: accountNum, routing, amount });
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Box
          pt={2}
          justifyContent="space-between"
          display="flex"
          width="100%"
          alignItems="center"
          pb={2}
        >
          <Box>
            <Typography variant="body1">Amount:</Typography>
          </Box>

          <Box width="50%">
            <CurrencyInput
              autoFocus={true}
              error={memoizedError}
              onChange={onAmountChange}
              value={amount}
              defaultValue="0.00"
            />
          </Box>
        </Box>

        <Typography align="right" color="textSecondary" variant="body2">
          Withdrawl maximum is $50,000 per day
        </Typography>

        <Box display="flex" alignItems="center" height={50} pt={2}>
          {memoizedError && (
            <Typography align="center" variant="body2" color="error">
              You do not have enough available cash to complete this transaction
            </Typography>
          )}
        </Box>

        <Box pt={3} width="100%">
          <Typography variant="body1" gutterBottom={true}>
            Recipient:
          </Typography>

          <Typography variant="body2">
            Please provide wire instructions. US Banks only.
          </Typography>

          <Box mt={1} pb={2}>
            <DefaultInput
              required={true}
              onChange={handleRoutingChange}
              value={routing}
              textAlign="left"
              placeholder="Routing Number"
            />
          </Box>

          <DefaultInput
            required={true}
            onChange={handleAccountNumChange}
            value={accountNum}
            textAlign="left"
            placeholder="Account Number"
          />
        </Box>

        <Box pt={4} width="100%" display="flex" justifyContent="center">
          <Box width="57%">
            <Button
              disabled={loading || memoizedError}
              type="submit"
              fullWidth={true}
              size="large"
              variant="contained"
              color="primary"
            >
              Submit
            </Button>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default WithdrawalForm;
