// import { addYears, isBefore } from "date-fns";
import Validator from "validatorjs";

// Validator.register(
//   "over16",
//   (value: any, requirement: any, attribute: any) => {
//     const dob = new Date(value);
//     // my dob years is before today - 16 years
//     return isBefore(dob, addYears(new Date(), -16));
//   },
//   "You do not meet the required age."
// );

Validator.register(
  "telephone",
  (value: any, requirement: any, attribute: any) => {
    const regex = /((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}/;

    return regex.test(value);
  },
  "Please provide a valid phone number, area code first."
);

Validator.register(
  "ssn",
  (value: any, requirement: any, attribute: any) => {
    const regex = /^\d{3}-\d{2}-\d{4}$/;

    return regex.test(value);
  },
  "Please provide a valid social security number"
);

export default Validator;
