import React, { FC } from 'react';

import { Route } from 'react-router';
import { RouteProps } from 'react-router-dom';

interface IProps extends RouteProps {
  authenticationRequired?: boolean;
}

const SecureRoute: FC<IProps> = React.memo(({ ...props }) => {
  return <Route {...props} />;
});

export default SecureRoute;
