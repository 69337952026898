// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { action, observable } from 'mobx';

import Validator from 'core/validations';

/**
 * class is responsible for getting/setting values and error messages for a particular input
 */
class FormField<T> {
  private static VALIDATION_DELAY_MS = 376;

  @observable public value?: T;
  @observable public errMsg: string;

  private validationTimeout: any;

  private validators: string[] = [];
  private customErrors = {};

  constructor(value?: T, validators: string[] = [], errorMessages: any = {}) {
    this.value = value;
    this.errMsg = '';
    this.validators = validators;
    this.customErrors = errorMessages;
    this.validationTimeout = undefined;
  }

  // set value
  // clear error message
  // start validations
  @action public set = (value: T) => {
    this.value = value;
    this.errMsg = '';
    this.runValidations();
  };

  // clear timeout to throttle and delay showing error until user has
  // finished typing in the input
  private runValidations = () => {
    const self = this;
    clearTimeout(this.validationTimeout);

    this.validationTimeout = setTimeout(() => {
      if (this.validators.length < 1) {
        return (this.errMsg = '');
      }

      const validator = new Validator(
        { value: this.value },
        { value: this.validators },
        this.customErrors
      );

      validator.passes(function () {
        // Validation passed
        self.errMsg = '';
      });

      validator.fails(function () {
        self.errMsg = validator.errors.get('value').join(' ');
      });
    }, FormField.VALIDATION_DELAY_MS);
  };
}

export default FormField;
