import { Box, Typography } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import useTransferHistory from 'hooks/useTransferHistory';
import React from 'react';

interface Params {
  description?: boolean;
}

const TransferPendingAlert = ({ description }: Params) => {
  const { hasPending } = useTransferHistory();

  if (!hasPending) {
    return null;
  }

  return (
    <>
      <Box
        color="#fff"
        width="max-content"
        px={1}
        py={0.5}
        bgcolor={'orange'}
        borderRadius={4}
        display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}
      >
        <Info fontSize="small" />

        <Typography variant="h4" style={{ marginLeft: 8 }}>
          ACH Deposit Pending
        </Typography>
      </Box>

      {!!description && (
        <Box mt={2}>
          <Typography variant="body1">
            You have an existing deposit that is still pending. Are you sure you
            want to proceed with a new deposit?
          </Typography>
        </Box>
      )}
    </>
  );
};

export default TransferPendingAlert;
