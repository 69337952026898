import React, { PureComponent } from "react";
import { toCurrency } from "./currency-conversion";
import DefaultInput from "./DefaultInput";

interface ICurrencyInputProps {
  onChange: (e: any, valueAsCurrency?: string) => void;
  defaultValue: string;
  value: string;
  warning?: boolean;
  error?: boolean;
  autoFocus?: boolean;
}

interface ICurrencyInputState {
  value: string;
}

class CurrencyInput extends PureComponent<
  ICurrencyInputProps,
  ICurrencyInputState
> {
  get value() {
    return this.props.value ? this.state.value : "$0.00";
  }

  public state = {
    value: this.props.defaultValue || "$0.00"
  };

  public handleChange = (event: any) => {
    const { onChange } = this.props;
    const valueAsCurrency = toCurrency(event.target.value, ".");

    this.setState({ value: valueAsCurrency });

    if (onChange) {
      onChange(event, valueAsCurrency);
    }
  };

  public render() {
    const {
      handleChange,
      props: { defaultValue, warning, error, ...inputProps },
      value
    } = this;

    return (
      <DefaultInput
        {...inputProps}
        inputProps={{
          type: "tel"
        }}
        positionCursorAtLast={true}
        value={value}
        onChange={handleChange}
        error={error}
        warning={warning}
      />
    );
  }
}

export default CurrencyInput;
