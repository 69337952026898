import React, { FC, useContext, useEffect, useState, useCallback } from 'react';

import { observer } from 'mobx-react-lite';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

import { BankingApi, Configuration, PlaidApi } from '@optionsai/oai-api-js';

import BankingContext from 'contexts/BankingContext';

import LinkedAccount from 'components/Banking/LinkedAccount';

import { usePlaidLink } from 'react-plaid-link';

import config from 'core/api/config';
import { useOktaAuth } from '@okta/okta-react';
import SuccessfulBankConnectionDialog from 'components/Banking/SuccessfulBankConnectionDialog';

const LinkedAccounts: FC = observer(() => {
  const { oktaAuth } = useOktaAuth();
  const banking = useContext(BankingContext);
  const [loading, setLoading] = useState(false);
  const [stale, setStale] = useState(true);
  const [linkToken, setLinkToken] = useState('');
  const [bankingApi, setBankingApi] = useState<BankingApi>();
  const [plaidApi, setPlaidApi] = useState<PlaidApi>();
  const [error, setError] = useState<Error>();
  const [connected, setConnected] = useState(false);

  useEffect(() => {
    const accessToken = oktaAuth.getAccessToken();

    try {
      if (!accessToken) {
        throw new Error('Not logged in. No access token.');
      }

      const configuration = new Configuration({
        basePath: config.basePath,
        accessToken,
      });

      const bankingApi = new BankingApi(configuration);
      const plaidApi = new PlaidApi(configuration);

      setBankingApi(bankingApi);
      setPlaidApi(plaidApi);
    } catch (err) {
      setError(err as any);
    }
  }, [oktaAuth]);

  useEffect(() => {
    if (!bankingApi) {
      return;
    }

    if (!stale) {
      return;
    }

    setLoading(true);

    bankingApi
      .getLinkedAccounts()
      .then((accounts) => banking.setLinkedAccounts(accounts))
      .catch((err) => setError(err))
      .finally(() => {
        setLoading(false);
        setStale(false);
      });
  }, [banking, bankingApi, stale]);

  useEffect(() => {
    if (!plaidApi) {
      return;
    }

    setLoading(true);

    plaidApi
      .createLinkToken()
      .then((token) => setLinkToken(token.linkToken || ''))
      .catch((err) => setError(err))
      .finally(() => setLoading(false));
  }, [plaidApi]);

  const onSuccess = useCallback(
    async (publicToken: any, metaData: any) => {
      if (!bankingApi) {
        return;
      }

      setLoading(true);

      const { account_id } = metaData;

      return bankingApi
        .linkAccount({
          linkAccount: {
            accountId: account_id,
            publicToken: publicToken,
          },
        })
        .catch((err) => setError(err))
        .finally(() => {
          setLoading(false);
          setStale(true);
          setConnected(true);
        });
    },
    [bankingApi]
  );

  const plaidConfig = {
    token: linkToken,
    onSuccess,
  };

  const closeConnectedMessage = useCallback(() => {
    setConnected(false);
  }, [setConnected]);

  const { open, ready } = usePlaidLink(plaidConfig);

  const linkedAccounts = banking.linkedAccounts;
  const hasLinkedAccounts = banking.hasLinkedAccounts;

  return (
    <Box padding={2}>
      <Typography variant="h3" gutterBottom={true}>
        Linked Accounts
      </Typography>

      {!linkedAccounts.length && (
        <Typography>Link an account to get started</Typography>
      )}

      {error && (
        <Typography color="error" variant="h4">
          {error.message}
        </Typography>
      )}

      <List>
        {linkedAccounts.map((link) => {
          const onRemove = async () => {
            if (!bankingApi) {
              return;
            }

            setLoading(true);

            return bankingApi
              .removeLinkedAccount({ linkedAccountId: link.id })
              .catch((err) => {
                err.json().then((data: any) => {
                  setError(new Error(data.message));
                });
              })
              .finally(() => {
                setLoading(false);
                setStale(true);
              });
          };

          const canRemove = !loading && !stale;

          return (
            <LinkedAccount
              {...link}
              key={link.id}
              canRemove={canRemove}
              onRemove={onRemove}
            />
          );
        })}
      </List>
      {loading && <CircularProgress />}
      {!loading && bankingApi && hasLinkedAccounts && (
        <Button onClick={() => open()} disabled={!ready}>
          Link another account
        </Button>
      )}
      {!loading && bankingApi && !hasLinkedAccounts && (
        <Button onClick={() => open()} disabled={!ready}>
          <AccountBalanceIcon color="primary" />
          <Typography variant="h5" style={{ marginLeft: 16 }} color="primary">
            Link Account
          </Typography>{' '}
        </Button>
      )}

      <SuccessfulBankConnectionDialog
        onClose={closeConnectedMessage}
        open={connected}
      />
    </Box>
  );
});

export default LinkedAccounts;
