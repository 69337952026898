import React, { FC } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';

interface IProps {
  name: string;
  accountType: string;
  accountNumber: string;
  status: string;
  canRemove?: boolean;
  onRemove: () => void;
}

const LinkedAccount: FC<IProps> = ({
  name,
  accountType,
  accountNumber,
  onRemove,
  canRemove = true,
}) => (
  <Box marginBottom={1}>
    <Paper variant="outlined">
      <ListItem disabled={!canRemove}>
        <ListItemText
          primary={name}
          secondary={`${accountType} (${accountNumber})`}
          primaryTypographyProps={{
            variant: 'h4',
          }}
          secondaryTypographyProps={{
            variant: 'h5',
          }}
        />
        <ListItemSecondaryAction>
          <Button disabled={!canRemove} onClick={onRemove}>
            Remove
          </Button>
        </ListItemSecondaryAction>
      </ListItem>
    </Paper>
  </Box>
);

export default LinkedAccount;
