import React, { FC, useMemo } from 'react';

import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import ResponsiveLayout from 'pages/ResponsiveLayout';
import CompleteIcon from 'components/UI/icons/CompleteIcon';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  transferTitle: {
    fontSize: '16px',
    fontWeight: 700,
    color: '#2C74F6',
    margin: '20px 0px',
  },
  transferSubtitle: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#828282',
    marginBottom: '5px',
    maxWidth: '335px',
    whiteSpace: 'pre-line',
  },
});

const SuccessPage: FC<RouteComponentProps> = ({ history }) => {
  const onBack = async () => history.replace('/banking');

  const styles = useStyles();

  const successMessage = useMemo(() => {
    if (history.location.pathname === '/transfers/success/withdrawal') {
      return '';
    }

    if (history.location.pathname === '/transfers/success/deposit') {
      return `ACH deposits made before 2pm ET typically take 1 business day to be made available for trading. \n\n Transfers made after 2pm will be processed the following day.`;
    }
  }, [history]);

  return (
    <>
      <ResponsiveLayout
        pageTitle="Transfer Initiated"
        returnPageTitle="Account"
        returnPageHandler={onBack}
      >
        <Box
          display={'flex'}
          height={'calc(100vh - 53px)'}
          width={'100%'}
          justifyContent="center"
          alignItems="center"
          padding={'0 40px'}
          paddingBottom={30}
        >
          <Box display="flex" flexDirection="column" alignItems="center">
            <CompleteIcon />

            <Box maxWidth={225}>
              <Typography align="center" className={styles.transferTitle}>
                Your transfer request has been received
              </Typography>
            </Box>

            {successMessage && (
              <Typography className={styles.transferSubtitle} align="center">
                {successMessage}
              </Typography>
            )}

            <Button
              variant="contained"
              color="primary"
              disableElevation
              fullWidth
              style={{ marginTop: 48 }}
              component={Link}
              to="/banking"
              replace={true}
            >
              Okay
            </Button>
          </Box>
        </Box>
      </ResponsiveLayout>
    </>
  );
};

export default withRouter(SuccessPage);
