import { REACT_APP_TRADE } from 'core/constants';
import queryString from 'query-string';
import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const useReturn = () => {
  const { search } = useLocation();
  const location = useLocation();
  const history = useHistory();

  const handleReturn = useCallback(() => {
    if (location.state && location.state.from) {
      return history.goBack();
    }

    const values = queryString.parse(search);

    let redirectUri = (values.redirectUri as string) || `${REACT_APP_TRADE}/`;

    window.location.href = redirectUri;
  }, [history, location.state, search]);

  return Object.freeze({ handleReturn });
};

export default useReturn;
