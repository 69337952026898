import { jwtDecode } from "jwt-decode";
import { useMemo } from "react";

export enum CBOE {
    ACTIVATED = 'activated',
    DEACTIVATED = 'deactivated',
}

interface ParsedToken {
    oai?: string;
    stripe?: string;
    groups?: string[],
    referred_by?: string,
    sub?: string;
    cboe_status?: CBOE;
}


const useParseToken = (token?: string) => {

    const parsedToken = useMemo(() => {

        if (!token) {
            return undefined;
        }

        return jwtDecode(token) as ParsedToken;
    }, [token])

    return parsedToken
}


export default useParseToken;