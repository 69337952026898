import React, { FC } from 'react';

import { RouteComponentProps, withRouter } from "react-router";

import ResponsiveLayout from 'pages/ResponsiveLayout';

import Withdraw from 'containers/Banking/Withdraw';

const WithdrawPage: FC<RouteComponentProps> = ({ history }) => (
  <ResponsiveLayout pageTitle="Withdraw" returnPageTitle="Banking" returnPageHandler={history.goBack}>
    <Withdraw />
  </ResponsiveLayout>
);

export default withRouter(WithdrawPage);
