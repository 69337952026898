import React, { FC } from 'react';

import Box from '@material-ui/core/Box';
import { Toolbar, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import NavSurface from '../NavSurface/NavSurface';
import getEnvVar from 'utils/getEnvVar';
import Logo from 'components/UI/Logo/Logo';

interface IDesktopNavbarProps {
  isSearching?: boolean;
  toggleSearch?: () => void;
  searchDisabled?: boolean;
}

const tradeUri = getEnvVar('REACT_APP_TRADE');

const DesktopNavbar: FC<IDesktopNavbarProps> = ({
  isSearching,
  toggleSearch,
  searchDisabled = false,
}) => {
  return (
    <NavSurface position="absolute">
      <Toolbar disableGutters={true}>
        <Box
          width="100%"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <a href={tradeUri}>
            <Box width={31}>
              <Logo />
            </Box>
          </a>

          <Box>
            {!searchDisabled && (
              <IconButton
                onClick={toggleSearch}
                data-testid="button-navsearchbutton"
                style={{ marginRight: -8 }}
              >
                {isSearching ? <CloseIcon /> : <SearchIcon />}
              </IconButton>
            )}
          </Box>
        </Box>
      </Toolbar>
    </NavSurface>
  );
};

export default DesktopNavbar;
