import React, { FC } from 'react';

import Dialog from 'components/UI/Dialog/Dialog';

import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Typography } from '@material-ui/core';
import TransferPendingAlert from 'containers/Banking/TransferPendingAlert';

const useStyles = makeStyles((theme) => ({
  dialog: {
    zIndex: 6,
    padding: '40px 50px',
    maxWidth: '600px',
    boxShadow: '0 4px 33px 8px rgb(0 0 0 / 10%) !important',
    borderRadius: '1px',
    display: 'block',
    [theme.breakpoints.down('xs')]: {
      margin: 0,
      height: '100%',
      maxWidth: '100%',
      maxHeight: '100%',
      padding: 30,
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  button: {
    height: '42px',
    borderRadius: '6px',
    color: theme.palette.primary.contrastText,
    fontSize: '14px !important',
    fontWeight: '600 !important' as any,
    textTransform: 'none !important' as any,
    whiteSpace: 'nowrap',
    opacity: 1,
    transition: 'all 0.3s',
    '&:hover': {
      backgroundColor: '#2C74F6',
      opacity: 0.8,
    },
    width: '-webkit-fill-available',
    maxWidth: '-webkit-fill-available',
    '@supports (width: -moz-available)': {
      maxWidth: '-mox-available',
      width: '-moz-available',
    },
  },
  cancelButton: {
    height: '42px',
    borderRadius: '6px',
    color: theme.palette.primary.main,
    fontSize: '14px !important',
    fontWeight: '600 !important' as any,
    textTransform: 'none !important' as any,
    whiteSpace: 'nowrap',
    opacity: 1,
    transition: 'all 0.3s',
    '&:hover': {
      backgroundColor: 'rgba(44, 116, 246, .1)',
      opacity: 0.8,
    },
    width: '-webkit-fill-available',
    maxWidth: '-webkit-fill-available',
    '@supports (width: -moz-available)': {
      maxWidth: '-mox-available',
      width: '-moz-available',
    },
  },
  title: {
    fontSize: '16px',
    fontWeight: 700,
  },
  subtitle: {
    fontsize: '14px',
    fontWeight: 600,
    color: '#828282',
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      justifyContent: 'normal',
    },
  },
  buttonSpacing: {
    width: '40px',
    [theme.breakpoints.down('xs')]: {
      height: '20px',
    },
  },
}));

interface Params {
  open?: boolean;
  onClose?: () => void;
  onSubmit?: () => void;
  onCancel?: () => void;
}

const ConfirmDepositDialog: FC<Params> = React.memo(
  ({ open = false, onClose, onCancel, onSubmit }) => {
    const styles = useStyles();

    return (
      <Dialog
        paperClassName={styles.dialog}
        open={open}
        noBackdrop
        onClose={onClose}
      >
        <div>
          <Typography className={styles.title}>Confirm Deposit</Typography>

          <Box py={2}>
            <TransferPendingAlert description={true} />
          </Box>

          <Typography className={styles.subtitle}>
            Returned ACH deposits incur a $30 clearing fee and may result in
            action against your account. Please confirm you have sufficient
            cleared funds in your account to complete this deposit.
          </Typography>
        </div>

        <div className={styles.buttonGroup}>
          <Button
            color="primary"
            variant="outlined"
            disableElevation
            className={styles.cancelButton}
            onClick={onCancel}
          >
            Cancel
          </Button>

          <div className={styles.buttonSpacing} />

          <Button
            color="primary"
            variant="contained"
            disableElevation
            className={styles.button}
            onClick={onSubmit}
          >
            Submit Deposit
          </Button>
        </div>
      </Dialog>
    );
  }
);

export default ConfirmDepositDialog;
